import { Button, Checkbox, CheckboxProps, Flex, Space } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  AdminService,
  CIQError,
  ComplianceStatus,
  Compliance as ComplianceType,
  DefaultToasterService,
  ErrorResponse,
  ExpertDetail,
  PROJECT_REFERRAL_STATE,
  ProjectAnswer,
  ReferralDetail,
  Slot,
  ToasterService
} from "@arbolus-technologies/api";
import {
  REFERRAL_SUB_STATE,
  RepeatedEngagementDetails
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";

import {
  REFERRAL_EXPERT_TABS,
  REFERRAL_EXPERT_TABS_TYPE,
  getReferralProfileTabs
} from "./../utils";
import { Compliance } from "./ReferralTableTabs/Compliance/Compliance";
import { Profile } from "./ReferralTableTabs/Profile/Profile";
import { Scheduling } from "./ReferralTableTabs/Scheduling/Scheduling";
import { Screening } from "./ReferralTableTabs/Screening/Screening";

interface ReferralTableTabsPanelProps {
  expertInfo: ExpertDetail;
  screeningInfo: ProjectAnswer[];
  complianceAnswers: ComplianceType[];
  repeatedEngagementDetails?: RepeatedEngagementDetails;
  referral: ReferralDetail;
  activeTab: REFERRAL_EXPERT_TABS_TYPE;
  editNote: boolean;
  setEditNote: (value: boolean) => void;
  onSaveNote: (note?: string) => void;
  onIsVerified: (isVerified: boolean) => void;
  onSetActiveTab: (tab: REFERRAL_EXPERT_TABS_TYPE) => void;
  onUpdateScreeningAnswers: (answers: ProjectAnswer[]) => void;
  onUpdateComplianceAnswers: (answers: ComplianceType[]) => void;
  onUpdateComplianceStatus: (
    chaperoneCall: boolean,
    complianceNote: string,
    status: PROJECT_REFERRAL_STATE,
    compliance: ComplianceStatus
  ) => void;
  adminService?: typeof AdminService;
  notificationService?: ToasterService;
}

export const ReferralTableTabsPanel: React.FC<ReferralTableTabsPanelProps> = ({
  expertInfo,
  screeningInfo,
  complianceAnswers,
  repeatedEngagementDetails,
  referral,
  activeTab,
  editNote,
  setEditNote,
  onSaveNote,
  onIsVerified,
  onSetActiveTab,
  onUpdateScreeningAnswers,
  onUpdateComplianceAnswers,
  onUpdateComplianceStatus,
  adminService = AdminService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralsTableExpertProfile");

  const [isLinkedinVerified, setIsLinkedinVerified] = useState<boolean>(
    expertInfo.isVerified
  );
  const [newNote, setNewNote] = useState(referral.note?.note ?? "");

  const isAdmin = useSelector(CacheSelector.isAdmin());
  const hasCompliance = useSelector(
    ProjectNxSelector.projectHasComplianceSelector()
  );

  const expertId = expertInfo.id;
  const validAvailabilitySlots = (
    referral.expertAvailabilitySlots || []
  ).filter((slot: Slot) => !slot.isExpired);

  const handleLinkedinVerification: CheckboxProps["onChange"] = (e) => {
    const isChecked = e.target.checked;
    setIsLinkedinVerified(isChecked);

    adminService.updateExpertIsVerified(expertId, isChecked).subscribe(
      () => {
        onIsVerified?.(isChecked);
        notificationService.showSuccess(t("linkedinStatusUpdated"));
      },
      (err: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(err);
      }
    );
  };

  const renderTabsPage = {
    [REFERRAL_EXPERT_TABS.PROFILE]: (
      <Profile
        expert={expertInfo}
        note={referral.note}
        referralId={referral.id}
        editNote={editNote}
        setEditNote={setEditNote}
        newNote={newNote}
        setNewNote={setNewNote}
        onSaveNote={onSaveNote}
      />
    ),
    [REFERRAL_EXPERT_TABS.SCREENING]: (
      <Screening
        screeningInfo={screeningInfo}
        expertId={expertId}
        referralStatus={referral.status}
        doNotContactStatus={expertInfo.doNotContactStatus}
        onUpdateScreeningAnswers={onUpdateScreeningAnswers}
      />
    ),
    [REFERRAL_EXPERT_TABS.COMPLIANCE]: (
      <Compliance
        expertId={expertId}
        referral={referral}
        complianceInfo={complianceAnswers}
        repeatedEngagementDetails={repeatedEngagementDetails}
        doNotContactStatus={expertInfo.doNotContactStatus}
        onUpdateComplianceAnswers={onUpdateComplianceAnswers}
        onUpdateComplianceStatus={onUpdateComplianceStatus}
      />
    ),
    [REFERRAL_EXPERT_TABS.SCHEDULING]: (
      <Scheduling
        referral={referral}
        expert={expertInfo}
        validAvailabilitySlots={validAvailabilitySlots}
      />
    )
  };

  const hasRepeatedEngagements = repeatedEngagementDetails
    ? repeatedEngagementDetails.pendingEngagements.length > 0 ||
      repeatedEngagementDetails.pastEngagements.length > 0
    : false;
  const REFERRAL_EXPERT_TABS_ACTIVE = getReferralProfileTabs(
    referral.application.subStatus === REFERRAL_SUB_STATE.REJECT,
    screeningInfo.length > 0,
    hasCompliance,
    hasRepeatedEngagements
  );

  return (
    <>
      <Flex justify="space-between" align="center">
        <Space.Compact>
          {(isAdmin || REFERRAL_EXPERT_TABS_ACTIVE.length > 1) &&
            REFERRAL_EXPERT_TABS_ACTIVE.map((tabKey) => (
              <Button
                key={tabKey}
                type={tabKey === activeTab ? "primary" : "default"}
                onClick={() => onSetActiveTab(tabKey)}
              >
                {t(tabKey)}
              </Button>
            ))}
        </Space.Compact>
        {isAdmin && (
          <Checkbox
            onChange={handleLinkedinVerification}
            checked={isLinkedinVerified}
          >
            {t("linkedinVerified")}
          </Checkbox>
        )}
      </Flex>
      {renderTabsPage[activeTab]}
    </>
  );
};
