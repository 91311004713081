import { useSelector } from "react-redux";

import { MonitoringListService } from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

export const useAddCompanies = () => {
  const { loggedInId } = useSelector(CacheSelector.currentUserSelector());

  const addCompanies = (companyIds: string[]) => {
    return MonitoringListService.addCompanies({
      companyIds,
      userIds: [loggedInId]
    });
  };

  return addCompanies;
};
