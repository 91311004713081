import { CanopyExpertApprovalStats } from "@arbolus-technologies/models/canopy";
import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { ComplianceWarningBody } from "./ComplianceWarningBody";

interface ComplianceWarningAlertProps {
  canopyExpertApprovalStats: CanopyExpertApprovalStats;
}

export const ComplianceWarningAlert: React.FC<ComplianceWarningAlertProps> = ({
  canopyExpertApprovalStats
}) => {
  const { t } = useTranslation("complianceWarning");

  return (
    <Alert
      message={t("complianceWarningTitle")}
      description={
        <ComplianceWarningBody
          canopyExpertApprovalStats={canopyExpertApprovalStats}
        />
      }
      type="warning"
      showIcon
    />
  );
};
