import { StrongText } from "@arbolus-technologies/ui/components";
import { Trans, useTranslation } from "react-i18next";

import styles from "./RequestConfirmationContent.module.scss";

export const RequestConfirmationContent: React.FC = () => {
  const { t } = useTranslation("userMonitoringListPage");

  return (
    <div>
      <p>
        <Trans
          i18nKey="userMonitoringListPage:requestModalContent1"
          // @ts-ignore
          components={{ b: <StrongText /> }}
        />
      </p>
      <p className={styles.confirmationDisclaimer}>
        {t("requestModalContent2")}
      </p>
    </div>
  );
};
