import { Observable } from "rxjs";

import {
  CANOPY_STATUS,
  CanopyExpertApprovalStats,
  EXPERT_CANOPY_STATUS,
  ExpertCanopyItem
} from "@arbolus-technologies/models/canopy";
import {
  Answer,
  AnswerExpert,
  AnswerMultiChoice,
  AnswerValue,
  CanopyQuestionModel,
  CanopySummary,
  ExpertAnswerModel,
  ExpertCanopyV2,
  NpsAnswers,
  ShortTextAnswer,
  VideoAnswer
} from "@arbolus-technologies/models/canopy-panels";

import {
  CANOPY_API,
  FEEDBACK_API,
  MAX_PAGE_SIZE,
  PROJECTS_API
} from "../constants/api";
import { SORT_DIRECTION } from "../enums/apiEnums";
import {
  CANOPY_ANSWERS_ORDER_BY,
  CANOPY_EXPERTS_ORDER_BY,
  CANOPY_ORDER_BY
} from "../enums/canopyEnums";
import {
  ApiNonPaginatedResponse,
  ApiPaginatedResponse,
  CreatedResponse,
  DeletedResponse,
  PaginatedRequest,
  ReferralExpert,
  SuccessResponse
} from "../models/api";
import {
  AllCanopy,
  AllCanopyAnswersByExpert,
  Canopy,
  CanopyAnswersByQuestion,
  CanopyDeleteRequest,
  CanopyDocumentRemove,
  CanopyExpertAgreement,
  CanopyExpertBaseAnswer,
  CanopyExpertsCount,
  CanopyFeedbackPostData,
  CanopyFeedbackPutData,
  CanopyPublic,
  CanopyQuestion,
  CanopyQuestionForExpert,
  CanopyQuestionsPDF,
  CanopyVideoTranscript,
  CreateCanopyDataRequest,
  CreateEditCanopyQuestion,
  EditCanopyDataRequest,
  ExpertCanopy,
  ExpertInACanopy,
  FeedbackCollectorType,
  ListCanopy,
  RequestCanopyTemplateResponse,
  ValueAnswersInterface
} from "../models/canopy";
import { FileResponse } from "../models/common";
import { restService } from "../restService";
import { restServiceV2 } from "../restServiceV2";

export const CanopyService = {
  getCanopies: (
    projectId: string
  ): Observable<ApiNonPaginatedResponse<ListCanopy>> =>
    restService.get<ApiNonPaginatedResponse<ListCanopy>>(
      CANOPY_API.GET_CANOPIES(projectId)
    ),
  getAllCanopies: (
    queryParams: PaginatedRequest<CANOPY_ORDER_BY>,
    searchTerm?: string,
    status?: CANOPY_STATUS,
    projectId?: string
  ): Observable<ApiPaginatedResponse<AllCanopy>> =>
    restService.get(CANOPY_API.GET_CANOPIES_V2(), {
      ...queryParams,
      searchTerm,
      status,
      projectId
    }),
  getCanopy: (canopyId: string): Observable<Canopy> =>
    restService.get<Canopy>(CANOPY_API.GET_CANOPY(canopyId)),
  getExpertsCount: (canopyId: string): Observable<CanopyExpertsCount> =>
    restService.get<CanopyExpertsCount>(CANOPY_API.GET_EXPERTS_COUNT(canopyId)),
  getExpertCanopy: (
    canopyId: string,
    expertId: string
  ): Observable<ExpertCanopy> =>
    restService.get<ExpertCanopy>(
      CANOPY_API.GET_EXPERT_CANOPY(canopyId, expertId)
    ),
  getPublicCanopyApi: (canopyId: string): Observable<CanopyPublic> =>
    restService.get<CanopyPublic>(CANOPY_API.GET_PUBLIC_CANOPY(canopyId)),
  deleteCanopy: (
    canopyData: CanopyDeleteRequest
  ): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      CANOPY_API.DELETE_CANOPY(),
      undefined,
      canopyData
    ),
  createCanopy: (
    canopyDataRequest: CreateCanopyDataRequest
  ): Observable<CreatedResponse> =>
    restServiceV2.post<CreatedResponse>(
      CANOPY_API.CREATE_CANOPY(),
      canopyDataRequest
    ),
  editCanopy: (
    canopyDataRequest: EditCanopyDataRequest
  ): Observable<CreatedResponse> =>
    restService.put<CreatedResponse>(
      CANOPY_API.EDIT_CANOPY(),
      canopyDataRequest
    ),
  uploadDocumentToCanopy: (
    canopyId: string,
    document: FormData,
    onUploadProgress?: (progressEvent: ProgressEvent) => void
  ): Observable<CreatedResponse> =>
    restService.post(
      CANOPY_API.UPLOAD_DOCUMENT_TO_CANOPY(canopyId),
      document,
      undefined,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress
      }
    ),
  removeDocumentFromCanopy: (
    documentId: CanopyDocumentRemove
  ): Observable<CreatedResponse> =>
    restService.delete<CreatedResponse>(
      CANOPY_API.REMOVE_DOCUMENT(),
      undefined,
      documentId
    ),
  getCanopyQuestions: (
    canopyId: string,
    expertId?: string
  ): Observable<ApiNonPaginatedResponse<CanopyQuestion>> =>
    restService.get<ApiNonPaginatedResponse<CanopyQuestion>>(
      CANOPY_API.GET_CANOPY_QUESTIONS(canopyId, expertId)
    ),
  getCanopyQuestionsV2: (
    canopyId: string
  ): Observable<ApiNonPaginatedResponse<CanopyQuestionModel>> =>
    restServiceV2.get<ApiNonPaginatedResponse<CanopyQuestionModel>>(
      CANOPY_API.GET_CANOPY_QUESTIONS_V2(canopyId)
    ),
  getCanopyQuestionVideoAnswers: (
    questionId: string,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = "Created",
    orderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<VideoAnswer>> =>
    restServiceV2.get<ApiPaginatedResponse<VideoAnswer>>(
      CANOPY_API.GET_CANOPY_QUESTION_VIDEO_ANSWERS(questionId),
      {
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),
  getCanopyQuestionNPSAnswer: (questionId: string): Observable<NpsAnswers> =>
    restService.get<NpsAnswers>(
      CANOPY_API.GET_CANOPY_QUESTION_NPS_ANSWERS(questionId)
    ),
  cloneCanopy: (canopyId: string): Observable<CreatedResponse> =>
    restService.post(CANOPY_API.CLONE_CANOPY(canopyId), {}),
  getCanopyQuestionMultiAnswers: (
    questionId: string
  ): Observable<AnswerMultiChoice> =>
    restService.get<AnswerMultiChoice>(
      CANOPY_API.GET_CANOPY_QUESTION_MULTI_ANSWERS(questionId)
    ),
  getCanopyQuestionValueAnswers: (
    questionId: string
  ): Observable<AnswerValue> =>
    restService.get<AnswerValue>(
      CANOPY_API.GET_CANOPY_QUESTION_VALUE_ANSWERS(questionId)
    ),
  getCanopyQuestionShortTextAnswers: (
    questionId: string,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = "Created",
    orderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<ShortTextAnswer>> =>
    restService.get<ApiPaginatedResponse<ShortTextAnswer>>(
      CANOPY_API.GET_CANOPY_QUESTION_SHORT_TEXT_ANSWERS(questionId),
      {
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),

  getCanopyExpertAnswers: (
    expertId: string,
    canopyId: string
  ): Observable<ExpertAnswerModel> =>
    restServiceV2.get<ExpertAnswerModel>(
      CANOPY_API.GET_CANOPY_EXPERT_ANSWERS(expertId, canopyId)
    ),
  getCanopyExpertAnswer: (answerId: string): Observable<Answer> =>
    restServiceV2.get<Answer>(CANOPY_API.GET_EXPERT_ANSWER(answerId)),
  getCanopyFromApi: (
    canopyId: string
  ): Observable<ApiNonPaginatedResponse<Canopy>> =>
    restService.get<ApiNonPaginatedResponse<Canopy>>(
      CANOPY_API.GET_CANOPY(canopyId)
    ),
  getQuestionFromApi: (questionId: string): Observable<CanopyQuestion> =>
    restService.get<CanopyQuestion>(CANOPY_API.GET_QUESTION(questionId)),

  changeCanopyStatus: (
    canopyId: string,
    status: CANOPY_STATUS
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(CANOPY_API.CHANGE_CANOPY_STATUS(), {
      canopyId,
      status
    }),

  createCanopyQuestion: (
    createData: CreateEditCanopyQuestion
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(CANOPY_API.CREATE_QUESTION(), createData),

  editCanopyQuestion: (
    editData: CreateEditCanopyQuestion
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      CANOPY_API.EDIT_CANOPY_QUESTION(),
      editData
    ),
  deleteCanopyQuestion: (questionId: string): Observable<SuccessResponse> =>
    restService.delete<SuccessResponse>(
      CANOPY_API.DELETE_CANOPY_QUESTION(questionId)
    ),
  assignExpertToCanopy: (
    expertId: string,
    canopyId: string
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(CANOPY_API.ASSIGN_EXPERT_TO_CANOPY(), {
      expertId,
      canopyId
    }),
  uploadCanopyVideo: (
    questionId: string,
    videoBlob: FormData
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.UPLOAD_VIDEO_TO_CANOPY(questionId),
      videoBlob,
      undefined,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    ),
  sendCanopyAnswers: (canopyId: string): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(CANOPY_API.EDIT_CANOPY_ANSWERS(), {
      canopyId
    }),
  getCanopyAnswerFromApi: (
    questionId: string,
    expertId: string
  ): Observable<CanopyExpertBaseAnswer> =>
    restService.get<CanopyExpertBaseAnswer>(
      CANOPY_API.CANOPY_ANSWER_DATA(expertId, questionId)
    ),
  deleteCanopyAnswer: (answerId: string): Observable<DeletedResponse> =>
    restService.delete<DeletedResponse>(
      CANOPY_API.DELETE_CANOPY_ANSWER(answerId)
    ),
  getAnswersFromQuestion: (
    questionId: string,
    offset: number,
    limit: number
  ): Observable<CanopyAnswersByQuestion> =>
    restService.get<CanopyAnswersByQuestion>(
      CANOPY_API.GET_QUESTION_ANSWERS(questionId),
      {
        offset,
        limit
      }
    ),
  getVideoTranscript: (answerId: string): Observable<CanopyVideoTranscript> =>
    restService.get<CanopyVideoTranscript>(
      CANOPY_API.GET_VIDEO_TRANSCRIPT(answerId)
    ),
  editVideoTranscript: (
    answerId: string,
    content: string
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      CANOPY_API.EDIT_VIDEO_TRANSCRIPT(answerId),
      {
        content
      }
    ),
  resetVideoTranscript: (answerId: string): Observable<{ content: string }> =>
    restService.put<{ content: string }>(
      CANOPY_API.RESET_VIDEO_TRANSCRIPT(answerId),
      {}
    ),
  getExpertsInACanopy: (
    canopyId: string,
    onlyComplete: boolean
  ): Observable<ApiNonPaginatedResponse<ExpertInACanopy>> =>
    restService.get<ApiNonPaginatedResponse<ExpertInACanopy>>(
      CANOPY_API.GET_EXPERTS_IN_A_CANOPY(canopyId),
      { onlyComplete }
    ),
  getExpertsInACanopyV2: (
    canopyId: string,
    status?: string | null,
    offset = 0,
    limit = 1000,
    orderBy = CANOPY_EXPERTS_ORDER_BY.JoinDate,
    orderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiNonPaginatedResponse<ExpertCanopyV2>> =>
    restServiceV2.get<ApiNonPaginatedResponse<ExpertCanopyV2>>(
      CANOPY_API.GET_EXPERTS_IN_A_CANOPY(canopyId),
      { status, offset, limit, orderBy, orderDirection }
    ),
  getAllExpertAnswersFromApi: (
    canopyId: string,
    expertId: string,
    offset: number,
    limit: number,
    orderBy = "question.sortOrder",
    orderDirection = SORT_DIRECTION.ASCENDING
  ): Observable<AllCanopyAnswersByExpert> =>
    restService.get<AllCanopyAnswersByExpert>(
      CANOPY_API.GET_ALL_EXPERTS_ANSWERS(canopyId, expertId),
      { offset, limit, orderBy, orderDirection }
    ),
  getIsExpertInAProject: (
    projectId: string,
    expertId: string
  ): Observable<ReferralExpert> =>
    restService.get<ReferralExpert>(
      PROJECTS_API.GET_IS_EXPERT_IN_PROJECT(projectId, expertId)
    ),
  setComplianceReviewStatus: (
    canopyId: string,
    expertId: string,
    hasPassedCompliance: boolean,
    answers: string[]
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      CANOPY_API.SET_COMPLIANCE_REVIEW_STATUS(),
      {
        canopyId,
        expertId,
        hasPassedCompliance,
        answers
      }
    ),
  submitCanopyEngagementAgreement: (
    canopyId: string,
    expertId: string
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.ADD_EXPERT_CANOPY_AGREEMENT(canopyId, expertId),
      {}
    ),
  resetExpertAnswers: (
    canopyId: string,
    expertId: string
  ): Observable<SuccessResponse> =>
    restService.put<SuccessResponse>(
      CANOPY_API.RESET_EXPERT_ANSWERS(canopyId, expertId),
      {}
    ),
  exportAnswers: (canopyId: string): Observable<FileResponse> =>
    restService.get<FileResponse>(CANOPY_API.EXPORT_ANSWERS(canopyId)),

  getCanopyQuestionForExpert: (
    questionId: string
  ): Observable<CanopyQuestionForExpert> =>
    restService.get<CanopyQuestionForExpert>(
      CANOPY_API.GET_QUESTION(questionId)
    ),
  getCanopyAnswerForExpert: (
    questionId: string,
    expertId: string
  ): Observable<CanopyQuestionForExpert> =>
    restService.get<CanopyQuestionForExpert>(
      CANOPY_API.CANOPY_ANSWER_DATA(expertId, questionId)
    ),
  postCanopyAnswerMultiForExpert: (
    questionId: string,
    data: { choiceIds: string[]; otherText: string; questionId: string }
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.CANOPY_ANSWER_MULTI_DATA(questionId),
      data
    ),
  putCanopyAnswerMultiForExpert: (
    questionId: string,
    data: { choiceIds: string[]; otherText: string; questionId: string }
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.CANOPY_ANSWER_MULTI_DATA(questionId),
      data
    ),
  postCanopyAnswerValueForExpert: (
    questionId: string,
    data: ValueAnswersInterface
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.CANOPY_ANSWER_VALUE_DATA(questionId),
      data
    ),
  putCanopyAnswerValueForExpert: (
    questionId: string,
    data: ValueAnswersInterface
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.CANOPY_ANSWER_VALUE_DATA(questionId),
      data
    ),
  postCanopyAnswerShortTextForExpert: (
    questionId: string,
    data: { shortText: string }
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.CANOPY_ANSWER_SHORT_TEXT_DATA(questionId),
      data
    ),
  putCanopyAnswerShortTextForExpert: (
    questionId: string,
    data: { shortText: string }
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.CANOPY_ANSWER_SHORT_TEXT_DATA(questionId),
      data
    ),
  postCanopyAnswerNpsForExpert: (
    questionId: string,
    data: { npsValue: number | null }
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.CANOPY_ANSWER_NPS_DATA(questionId),
      data
    ),
  putCanopyAnswerNpsForExpert: (
    questionId: string,
    data: { npsValue: number | null }
  ): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.CANOPY_ANSWER_NPS_DATA(questionId),
      data
    ),
  getOptionExperts: (
    choiceId: string,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = CANOPY_ANSWERS_ORDER_BY.Created,
    orderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<AnswerExpert>> =>
    restService.get<ApiPaginatedResponse<AnswerExpert>>(
      CANOPY_API.GET_MULTICHOICE_ANSWER_EXPERTS(),
      { choiceId, offset, limit, orderBy, orderDirection }
    ),
  getValueAnswersExpert: (
    questionId: string,
    choiceId?: string | null,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = CANOPY_ANSWERS_ORDER_BY.Created,
    orderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<AnswerExpert>> =>
    restService.get<ApiPaginatedResponse<AnswerExpert>>(
      CANOPY_API.GET_VALUE_ANSWER_EXPERTS(),
      {
        questionId,
        choiceId,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),
  getNpsAnswersExpert: (
    questionId: string,
    npsValue: number | null,
    offset = 0,
    limit = MAX_PAGE_SIZE,
    orderBy = CANOPY_ANSWERS_ORDER_BY.Created,
    orderDirection: string = SORT_DIRECTION.DESCENDING
  ): Observable<ApiPaginatedResponse<AnswerExpert>> =>
    restService.get<ApiPaginatedResponse<AnswerExpert>>(
      CANOPY_API.GET_NPS_ANSWER_EXPERTS(),
      {
        questionId,
        npsValue,
        offset,
        limit,
        orderBy,
        orderDirection
      }
    ),
  postCanopySummary: (canopyId: string): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      CANOPY_API.POST_CANOPY_SUMMARY(canopyId),
      ""
    ),
  getCanopySummary: (canopyId: string): Observable<CanopySummary> =>
    restService.get<CanopySummary>(CANOPY_API.GET_CANOPY_SUMMARY(canopyId)),
  getCanopyAgreement: (canopyId: string): Observable<CanopyExpertAgreement> =>
    restService.get<CanopyExpertAgreement>(
      CANOPY_API.GET_CANOPY_AGREEMENT(canopyId)
    ),
  getAllPdfAnswers: (
    canopyId: string
  ): Observable<{ items: CanopyQuestionsPDF }> =>
    restService.get<{ items: CanopyQuestionsPDF }>(
      CANOPY_API.GET_ANSWERS_PDF(canopyId)
    ),
  canopyFeedback: (data: CanopyFeedbackPostData): Observable<CreatedResponse> =>
    restService.post<CreatedResponse>(
      FEEDBACK_API.CANOPY_FEEDBACK_COLLECTOR(),
      data
    ),
  canopyFeedbackUpdate: (
    data: CanopyFeedbackPutData
  ): Observable<CreatedResponse> =>
    restService.put<CreatedResponse>(
      FEEDBACK_API.CANOPY_FEEDBACK_COLLECTOR(),
      data
    ),
  getFeedbackQuestion: (
    type: FeedbackCollectorType
  ): Observable<{ question: string }> =>
    restService.post<{ question: string }>(
      FEEDBACK_API.GET_CANOPY_FEEDBACK_QUESTION(),
      { type }
    ),
  requestCanopyTemplates: (): Observable<RequestCanopyTemplateResponse> =>
    restService.get<RequestCanopyTemplateResponse>(
      CANOPY_API.REQUEST_TEMPLATE_LIST()
    ),
  getExpertCanopies: (
    searchTerm: string,
    status?: EXPERT_CANOPY_STATUS[],
    offset = 0,
    limit = MAX_PAGE_SIZE
  ): Observable<ApiPaginatedResponse<ExpertCanopyItem>> =>
    restService.get<ApiPaginatedResponse<ExpertCanopyItem>>(
      CANOPY_API.GET_CANOPY_EXPERTS(),
      {
        offset,
        limit,
        searchTerm,
        status
      }
    ),
  getCanopyExpertApprovalStats: (
    canopyId: string,
    expertId: string
  ): Observable<CanopyExpertApprovalStats> =>
    restService.get<CanopyExpertApprovalStats>(
      CANOPY_API.GET_CANOPY_EXPERT_APPROVAL_STATS(canopyId, expertId)
    )
};
