import { Button } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { BoxButton } from "@arbolus-technologies/ui/components";

interface CreateSimpleCanopyButtonProps {
  buttonType: "default" | "dashboard" | "grayBoxButton";
  onTrack: () => void;
}

export const CreateSimpleCanopyButton: React.FC<
  CreateSimpleCanopyButtonProps
> = ({ buttonType, onTrack }) => {
  const { t } = useTranslation("createSimpleCanopyButton");
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    onTrack();
    dispatch(PanelStoreActions.openPanel(PanelId.CreateSimpleCanopy));
  }, [dispatch, onTrack]);

  return (
    <>
      {buttonType === "dashboard" && (
        <BoxButton
          text={t("fastTrack")}
          icon={{
            name: "workspaces",
            color: ARBOLUS_COLORS.bColorBaseWhite,
            background: ARBOLUS_COLORS.bColorBasePurple
          }}
          subTitle={t("arbolusCanopy")}
          onClick={handleClick}
          isPurple
        />
      )}
      {buttonType === "default" && (
        <Button
          icon={<AntDIcon name="add" />}
          type="primary"
          onClick={handleClick}
        >
          {t("newCanopy")}
        </Button>
      )}

      {buttonType === "grayBoxButton" && (
        <Button
          icon={<AntDIcon name="add" />}
          iconPosition="end"
          type="link"
          onClick={handleClick}
        >
          {t("newCanopy")}
        </Button>
      )}
    </>
  );
};
