import { Button, Divider, Flex, Form, Input, Radio } from "antd";
import i18next from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  ComplianceApprovalStatusRequest,
  ComplianceStatus,
  DefaultToasterService,
  ErrorResponse,
  PROJECT_REFERRAL_STATE,
  ProjectService,
  ToasterService
} from "@arbolus-technologies/api";
import {
  COMPLIANCE_QUESTION_APPROVAL_FORM,
  COMPLIANCE_STATUS
} from "@arbolus-technologies/models/common";
import { MAX_COMPLIANCE_COMMENT_LENGTH } from "@arbolus-technologies/ui/components";

const COMPLIANCE_APPROVAL_STATUSES = [
  {
    label: i18next.t("referralCompliance:passCompliance"),
    value: COMPLIANCE_STATUS.ACCEPT
  },
  {
    label: i18next.t("referralCompliance:failCompliance"),
    value: COMPLIANCE_STATUS.REJECT
  }
];

const COMPLIANCE_CALL = [
  { label: i18next.t("referralCompliance:wantToJoin"), value: true },
  { label: i18next.t("referralCompliance:notWantToJoin"), value: false }
];

const { CHAPERONE_CALL, COMPLIANCE, COMPLIANCE_NOTE } =
  COMPLIANCE_QUESTION_APPROVAL_FORM;

interface ComplianceQuestionsApprovalProps {
  referralId: string;
  projectId: string;
  onUpdateComplianceStatus: (
    chaperoneCall: boolean,
    complianceNote: string,
    status: PROJECT_REFERRAL_STATE,
    compliance: ComplianceStatus
  ) => void;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

export const ComplianceQuestionsApproval: React.FC<
  ComplianceQuestionsApprovalProps
> = ({
  referralId,
  projectId,
  onUpdateComplianceStatus,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}) => {
  const { t } = useTranslation("referralCompliance");
  const [form] = Form.useForm();

  const { compliance } = Form.useWatch([], form) ?? {};

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = (values: ComplianceApprovalStatusRequest) => {
    setIsSaving(true);
    projectService
      .updateComplianceApprovalStatus(projectId, referralId, values)
      .subscribe(
        ({ compliance, status }) => {
          onUpdateComplianceStatus(
            !!values[CHAPERONE_CALL],
            values[COMPLIANCE_NOTE],
            status,
            compliance
          );
          notificationService.showSuccess(t("reviewalSuccess"));
          setIsSaving(false);
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
          setIsSaving(false);
        }
      );
  };

  const onFinishFailed = () => {
    notificationService.showError(t("invalidForm"));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      disabled={isSaving}
    >
      <Form.Item
        label={t("hasExpertPassedCompliance")}
        name={COMPLIANCE}
        rules={[{ required: true, message: t("radioError") }]}
      >
        <Radio.Group options={COMPLIANCE_APPROVAL_STATUSES} />
      </Form.Item>
      <Divider />
      <Form.Item
        label={t("includeInCall")}
        name={CHAPERONE_CALL}
        rules={[{ required: true, message: t("radioError") }]}
      >
        <Radio.Group
          options={COMPLIANCE_CALL}
          disabled={compliance === COMPLIANCE_STATUS.REJECT}
        />
      </Form.Item>
      <Divider />
      {compliance !== COMPLIANCE_STATUS.ACCEPT && (
        <Form.Item
          label={t("additionalComments")}
          name={COMPLIANCE_NOTE}
          rules={[
            {
              required: true,
              message: t("additionalCommentsRequiredError")
            },
            {
              max: MAX_COMPLIANCE_COMMENT_LENGTH,
              message: t("maxCommentLengthError", {
                length: MAX_COMPLIANCE_COMMENT_LENGTH
              })
            }
          ]}
        >
          <Input.TextArea
            rows={4}
            placeholder={t("additionalCommentsPlaceholder")}
          />
        </Form.Item>
      )}

      <Form.Item>
        <Flex justify="flex-end">
          <Button type="primary" htmlType="submit">
            {t("save")}
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
};
