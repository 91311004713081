import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  DefaultToasterService,
  Endorsement,
  ErrorResponse,
  ExpertDetail,
  ExpertService
} from "@arbolus-technologies/api";
import { useHistory } from "react-router";
import {
  ExpertNotificationDetails,
  ExpertProfileFormDetails
} from "../../helpers/types";

export function useProfileDetails(expertDetails: ExpertNotificationDetails) {
  const { t } = useTranslation("expertEditProfile");
  const history = useHistory();

  const [expertProfile, setExpertProfile] =
    useState<ExpertProfileFormDetails | null>(null);
  const [endorsements, setEndorsements] = useState<Endorsement[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getExpertDetails = useCallback(() => {
    ExpertService.getExpertById({
      expertId: expertDetails.expertId!,
      includeRateCard: true
    }).subscribe(
      (expert: ExpertDetail) => {
        const expertForm = getExpertFormFormatted(expert);
        setEndorsements(expert.endorsements);
        setExpertProfile(expertForm);
        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        DefaultToasterService.showError(error.message);
        setIsLoading(false);
      }
    );
  }, [expertDetails.expertId]);

  const updateExpertExperience = useCallback(
    async (background: string) => {
      const { id, overview, experienceLevel } = expertProfile!;

      try {
        await ExpertService.updateExpertProfile(
          id,
          overview!,
          experienceLevel!,
          ["", ""],
          "",
          background
        ).toPromise();
        setExpertProfile((prevState) => ({ ...prevState!, background }));
        DefaultToasterService.showSuccess(t("experienceUpdateSuccess"));
      } catch (error) {
        DefaultToasterService.showError(t("unableToSaveDetails"));
      }
    },
    [expertProfile, t]
  );

  return {
    getExpertDetails,
    updateExpertExperience,
    isLoading,
    endorsements,
    expertProfile
  };
}

const getExpertFormFormatted = (
  expert: ExpertDetail
): ExpertProfileFormDetails => {
  const {
    id,
    user: { firstName, lastName, phoneNumber, profileImageUrl, email, title },
    linkedinProfile,
    overview,
    experienceLevel,
    rateCards,
    background,
    user
  } = expert;

  return {
    id,
    firstName,
    lastName,
    email,
    phoneNumber,
    profileImageUrl,
    linkedinProfile,
    title,
    background,
    overview,
    experienceLevel,
    hourlyRate: rateCards && rateCards[0]?.price,
    isoCurrencyCode: rateCards && rateCards[0]?.isoCurrencyCode,
    user
  };
};
