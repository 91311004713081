import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  BasicUser,
  CIQError,
  ClientService,
  DefaultToasterService,
  ErrorResponse,
  EventService,
  GetEventResponse,
  NewCreateEventRequest,
  PROJECT_EVENT_PLATFORM,
  Project,
  ProjectService,
  ReferralDetail,
  ToasterService
} from "@arbolus-technologies/api";
import {
  DefaultTimezone,
  EventGuestOption
} from "@arbolus-technologies/models/common";
import {
  EVENT_FORM,
  EventFormInterface
} from "@arbolus-technologies/models/project";
import { PROJECT_CALENDAR_ROUTE } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import {
  TimezoneService,
  getFullNameOrEmailOrId
} from "@arbolus-technologies/utils";
import { Form, FormInstance } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

dayjs.extend(utc);

const {
  ATTACHMENTS,
  STARTING_DATE,
  ENDING_DATE,
  ENDING_TIME,
  FREE_TRANSCRIPT,
  GUESTS,
  NOTES,
  SMART_TRANSCRIPT,
  STARTING_TIME,
  TIMEZONE,
  TITLE
} = EVENT_FORM;

interface UseEventProps {
  eventId?: string;
  referralId: string | null;
  workspaceId: string | null;
  clientService?: typeof ClientService;
  eventService?: typeof EventService;
  projectService?: typeof ProjectService;
  notificationService?: ToasterService;
}

interface UseEvent {
  form: FormInstance<EventFormInterface>;
  eventDetails: GetEventResponse | null;
  isLoading: boolean;
  isSaving: boolean;
  isEditMode: boolean;
  isComplianceWarningModalOpen: boolean;
  handleSubmit: () => void;
  handleCancelComplianceWarningModal: () => void;
  handleConfirmComplianceWarningModal: () => void;
  handleComplianceBeforeSubmit: () => void;
  onFinishFailed: () => void;
  project: Project | null;
  currentBasicUser: BasicUser | null;
  defaultEventGuests?: EventGuestOption[];
  defaultTimezone?: DefaultTimezone;
  referral: ReferralDetail | null;
}

export const useEvent = ({
  eventId,
  referralId,
  workspaceId,
  clientService = ClientService,
  eventService = EventService,
  projectService = ProjectService,
  notificationService = DefaultToasterService
}: UseEventProps): UseEvent => {
  const { t } = useTranslation("eventForm");
  const [form] = Form.useForm<EventFormInterface>();
  const history = useHistory();

  const project = useSelector(ProjectNxSelector.projectData());
  const currentBasicUser = useSelector(CacheSelector.getCurrentBasicUser());
  const timezones = useSelector(CacheSelector.timezones());
  const clientId = useSelector(ProjectNxSelector.projectClientId());

  const [eventDetails, setEventDetails] = useState<GetEventResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isComplianceWarningModalOpen, setIsComplianceWarningModalOpen] =
    useState(false);
  const [referral, setReferral] = useState<ReferralDetail | null>(null);

  const isEditMode = !!eventDetails;

  const buildEventPayload = (
    values: EventFormInterface
  ): NewCreateEventRequest => {
    const startDate = values[STARTING_DATE];
    const startTime = values[STARTING_TIME];
    const endDate = values[ENDING_DATE];
    const endTime = values[ENDING_TIME];
    const timezone = values[TIMEZONE];

    const startTimeCombined = TimezoneService.mergeDateTimeWithTimeZone(
      startDate,
      startTime,
      timezone.value
    );
    const endTimeCombined = TimezoneService.mergeDateTimeWithTimeZone(
      endDate,
      endTime,
      timezone.value
    );

    return {
      title: values[TITLE],
      startTime: startTimeCombined,
      endTime: endTimeCombined,
      timezone: timezone.value,
      guests: values[GUESTS].map((guest) => guest.value),
      transcribe: values[FREE_TRANSCRIPT] ?? false,
      humanTranscribe: values[SMART_TRANSCRIPT] ?? false,
      notes: values[NOTES] ?? "",
      attachments: values[ATTACHMENTS].map((attachment) => attachment.id) ?? [],
      meetingPlatform: PROJECT_EVENT_PLATFORM.CONNECT,
      location: "", // This is technically nullable in BE (but it's not) and needs to be sent as an empty string,
      workspaceId: workspaceId ?? undefined // Only defined if event is created from chat
    };
  };

  const handleCancelComplianceWarningModal = (): void => {
    setIsComplianceWarningModalOpen(false);
  };

  const handleConfirmComplianceWarningModal = (): void => {
    setIsComplianceWarningModalOpen(false);
    handleSubmit();
  };

  const handleSubmit = () => {
    if (!project) return;

    setIsSaving(true);
    const formValues = form.getFieldsValue();
    const formattedEvent = buildEventPayload(formValues);

    let eventObservable;

    if (eventDetails) {
      eventObservable = eventService.newUpdateEvent(
        project.id,
        eventDetails.id,
        {
          ...formattedEvent,
          sendEmailNotifications: true
        }
      );
    } else {
      eventObservable = eventService.newCreateEvent(project.id, formattedEvent);
    }

    eventObservable.subscribe(
      () => {
        setIsSaving(false);
        notificationService.showSuccess(
          eventDetails ? t("eventUpdated") : t("eventCreated")
        );
        history.push(PROJECT_CALENDAR_ROUTE(project.id));
      },
      (error: ErrorResponse<CIQError>) => {
        notificationService.showApiErrors(error);
        setIsSaving(false);
      }
    );
  };

  const handleComplianceBeforeSubmit = (): void => {
    const formValues = form.getFieldsValue();
    const eventGuestsUserIds = formValues[GUESTS].map((guest) => guest.key);

    clientService
      .hasClientPendingCompliance(clientId, eventGuestsUserIds, eventId)
      .subscribe(
        (response) => {
          if (response.clientGateKeepingPending) {
            setIsComplianceWarningModalOpen(true);
          } else {
            handleSubmit();
          }
        },
        (error: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(error);
        }
      );
  };

  const onFinishFailed = () => {
    notificationService.showError(t("invalidForm"));
  };

  const defaultEventGuests: EventGuestOption[] | undefined = useMemo(() => {
    if (project) {
      const guests = eventDetails?.eventGuests?.length
        ? eventDetails.eventGuests.map(({ user }) => user)
        : project.defaultEventGuests;

      const defaultGuests = guests.map(
        ({ id, email, firstName, lastName }) => ({
          key: id,
          label: getFullNameOrEmailOrId(id, email, firstName, lastName),
          value: email
        })
      );

      // If referral is defined, add referralGuest to the list
      if (referral) {
        const referralGuest: EventGuestOption = {
          key: referral.expert.userId,
          label: getFullNameOrEmailOrId(
            referral.expert.id,
            referral.expert.email,
            referral.expert.firstName,
            referral.expert.lastName
          ),
          value: referral.expert.email
        };

        defaultGuests.push(referralGuest);
      }

      return defaultGuests;
    }
    return undefined;
  }, [eventDetails?.eventGuests, project, referral]);

  const defaultTimezone: DefaultTimezone | undefined = useMemo(() => {
    if (project) {
      if (eventDetails?.timezone?.id) {
        return {
          value: eventDetails.timezone.id,
          label: eventDetails.timezone.displayText
        };
      }

      if (project.timezone) {
        const projectTimezone = timezones.find(
          (timezone) => timezone.value === project.timezone
        );
        if (projectTimezone) {
          return {
            value: projectTimezone.value,
            label: projectTimezone.label
          };
        }
      }

      return undefined;
    }
    return undefined;
  }, [project, eventDetails?.timezone, timezones]);

  const getReferral = (project: Project, referralId: string) => {
    projectService.getReferral(project.id, referralId).subscribe(
      (response) => {
        if (response?.expertAvailabilitySlots) {
          const now = dayjs().tz(project.timezone);
          const filteredSortedSlots = response.expertAvailabilitySlots
            .filter((slot) => {
              const slotTime = dayjs.utc(slot.startTime).tz(project.timezone);
              return slotTime.isSameOrAfter(now, "minute");
            })
            .sort(
              (a, b) =>
                dayjs.utc(a.startTime).valueOf() -
                dayjs.utc(b.startTime).valueOf()
            );

          setReferral({
            ...response,
            expertAvailabilitySlots: filteredSortedSlots
          });
        } else {
          setReferral(response);
        }

        setIsLoading(false);
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
        notificationService.showApiErrors(error);
      }
    );
  };

  const getEvent = (project: Project, eventId: string) => {
    eventService.newGetEvent(project.id, eventId).subscribe(
      (eventResponse) => {
        setEventDetails(eventResponse);

        if (referralId) {
          getReferral(project, referralId);
        } else {
          setIsLoading(false);
        }
      },
      (error: ErrorResponse<CIQError>) => {
        setIsLoading(false);
        notificationService.showApiErrors(error);
      }
    );
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!project) return;
    setIsLoading(true);

    if (eventId) {
      getEvent(project, eventId);
    } else if (referralId) {
      getReferral(project, referralId);
    } else {
      setIsLoading(false);
    }
  }, [eventId, project, referralId]);

  return {
    form,
    eventDetails,
    isLoading,
    isSaving,
    isEditMode,
    isComplianceWarningModalOpen,
    handleSubmit,
    handleCancelComplianceWarningModal,
    handleConfirmComplianceWarningModal,
    handleComplianceBeforeSubmit,
    onFinishFailed,
    project,
    currentBasicUser,
    defaultEventGuests,
    defaultTimezone,
    referral
  };
};
