import React from "react";

import { AntDIcon, IconName } from "@arbolus-technologies/antDComponents";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Flex, Typography } from "antd";

const { Text } = Typography;

interface WarningCheckProps {
  iconName: IconName;
  iconColor: ARBOLUS_COLORS;
  text: string;
}

export const WarningCheck: React.FC<WarningCheckProps> = ({
  iconName,
  iconColor,
  text
}) => (
  <Flex align="center" gap={4}>
    <AntDIcon name={iconName} fontSize="16px" color={iconColor} />
    <Text>{text}</Text>
  </Flex>
);
