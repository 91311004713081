import React from "react";
import { useTranslation } from "react-i18next";

import {
  MixpanelPages,
  PageTracker,
  PolicyBanners,
  SimpleCanopyCreationProvider,
  SlidePanel
} from "@arbolus-technologies/features/common";
import { PanelId } from "@arbolus-technologies/stores/panels";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { SIDE_PANEL_SIZE, useDocumentTitle } from "@arbolus-technologies/utils";

import { DashboardCanopy } from "./DashboardModules/Canopy/DashboardCanopy";
import { DashboardMainButtons } from "./DashboardModules/DashboardMainButtons/DashboardMainButtons";
import { EarliestAvailabilities } from "./DashboardModules/EarliestAvailabilities/EarliestAvailabilities";
import { DashboardNotifications } from "./DashboardModules/Notifications/DashboardNotifications";
import { DashboardProjects } from "./DashboardModules/Projects/DashboardProjects";

import styles from "./DashboardPage.module.scss";

interface ClientDashboardPageProps {
  routes: {
    project: string;
    inbox: string;
  };
}

export const ClientDashboardPage: React.FC<ClientDashboardPageProps> = ({
  routes
}) => {
  const { t } = useTranslation("dashboardPage");
  useDocumentTitle("dashboard", "title");

  return (
    <PageTracker page={MixpanelPages.MainDashboard}>
      <MainPageLayout title="Dashboard">
        <PolicyBanners />
        <DashboardMainButtons />
        <div className={styles.splitView}>
          <DashboardNotifications route={routes.inbox} />
          <EarliestAvailabilities />
        </div>
        <DashboardCanopy />
        <DashboardProjects route={routes.project} />
      </MainPageLayout>
      <SlidePanel
        title={<div>{t("newCanopy")}</div>}
        panelId={PanelId.CreateSimpleCanopy}
        closeButtonDirection="right"
        width={SIDE_PANEL_SIZE._720}
      >
        <SimpleCanopyCreationProvider />
      </SlidePanel>
    </PageTracker>
  );
};
