import {
  DefaultToasterService,
  MonitoringListService
} from "@arbolus-technologies/api";
import {
  MixpanelPages,
  PageTracker
} from "@arbolus-technologies/features/common";
import { DASHBOARD } from "@arbolus-technologies/routes";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { MainPageLayout } from "@arbolus-technologies/ui/layout";
import { useDocumentTitle } from "@arbolus-technologies/utils";
import { GridApi } from "ag-grid-community";
import { Spin, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AddCompaniesModal } from "../../Components/AddCompaniesModal/AddCompaniesModal";
import { NoMonitoringListPage } from "../NoMonitoringListPage/NoMonitoringListPage";
import { UserMonitoringListTablePage } from "./UserMonitoringListTablePage";

export function UserMonitoringListPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [userHasMonitoringList, setUserHasMonitoringList] =
    useState<boolean>(false);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [numOfCompanies, setNumOfCompanies] = useState<number>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = useCallback(() => setIsModalOpen(true), []);
  const hideModal = useCallback(() => setIsModalOpen(false), []);

  const { t } = useTranslation("userMonitoringListPage");

  const { loggedInId, firstName } = useSelector(
    CacheSelector.currentUserSelector()
  );

  useDocumentTitle("userMonitoringListPage", "title", { firstName });

  useEffect(() => {
    handleCheckMonitoringList();
  }, []);

  const handleCheckMonitoringList = async () => {
    setLoading(true);
    try {
      const { userIsMonitoringCompanies } =
        await MonitoringListService.checkUserMonitoringList(loggedInId);
      setUserHasMonitoringList(userIsMonitoringCompanies);
      setLoading(false);
    } catch (error) {
      DefaultToasterService.showError(error.message);
      setLoading(false);
    }
  };

  return (
    <PageTracker page={MixpanelPages.MonitoringList}>
      <MainPageLayout
        title={t("title", { firstName })}
        backLink={DASHBOARD}
        rightButtonContainer={
          numOfCompanies && (
            <Tag>{`${numOfCompanies} / 40 ${t("companies")}`}</Tag>
          )
        }
        stickyHeader
      >
        {loading && <Spin size="large" />}

        {!loading && userHasMonitoringList && (
          <UserMonitoringListTablePage
            userId={loggedInId}
            showModal={showModal}
            onGridApiReady={setGridApi}
            setNumOfCompanies={setNumOfCompanies}
          />
        )}

        {!loading && !userHasMonitoringList && (
          <NoMonitoringListPage showModal={showModal} />
        )}

        <AddCompaniesModal
          isModalOpen={isModalOpen}
          hideModal={hideModal}
          userId={loggedInId}
          gridApi={gridApi}
          actionAfterClose={() => {
            if (!userHasMonitoringList) {
              handleCheckMonitoringList();
            }
          }}
        />
      </MainPageLayout>
    </PageTracker>
  );
}
