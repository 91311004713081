import {
  call_delete,
  call_get,
  call_post,
  call_put
} from "../SimpleRestService";
import { INSIGHTS_API } from "../endpoints/insights";
import { MONITORING_LIST_API } from "../endpoints/monitoring-list";
import { getMultiSortParams } from "../helpers";
import type { ApiPaginatedResponse } from "../models/api";
import type {
  CheckUserMonitoringListResponse,
  CompanyDataModel,
  CompanyDataRequest,
  DeleteCompanyMonitoringList,
  MonitoredCompanyReportingStateResponse,
  MonitoringListAddCompaniesRequest,
  MonitoringListModel,
  MonitoringListRequest,
  UpdateCompanySignalRequest,
  UserMonitoringListModel,
  UserMonitoringListRequest
} from "../models/monitoringList";

export function getCompanySignals(queryParams: MonitoringListRequest) {
  return call_get<ApiPaginatedResponse<MonitoringListModel>>(
    INSIGHTS_API.COMPANY_SIGNALS,
    getMultiSortParams(queryParams)
  );
}

export function updateCompanySignal(payload: UpdateCompanySignalRequest) {
  return call_put<UpdateCompanySignalRequest>(
    MONITORING_LIST_API.COMPANY_SIGNALS(),
    payload
  );
}

export function addCompanies(payload: MonitoringListAddCompaniesRequest) {
  return call_post<MonitoringListAddCompaniesRequest>(
    INSIGHTS_API.COMPANY_SIGNALS,
    payload
  );
}

export function deleteCompanySignal(id: string) {
  return call_delete(INSIGHTS_API.COMPANY_SIGNAL(id));
}

export function getUserCompanySignal(queryParams: UserMonitoringListRequest) {
  return call_get<ApiPaginatedResponse<UserMonitoringListModel>>(
    MONITORING_LIST_API.USER_MONITORING_LIST(queryParams.userId!),
    getMultiSortParams(queryParams)
  );
}

export function checkUserMonitoringList(userId: string) {
  return call_get<CheckUserMonitoringListResponse>(
    MONITORING_LIST_API.CHECK_USER_MONITORING_LIST(userId)
  );
}

export function deleteCompanyMonitoringList(
  userId: string,
  payload: {
    companyIds: string[];
  }
) {
  return call_delete<DeleteCompanyMonitoringList>(
    MONITORING_LIST_API.USER_MONITORING_LIST(userId),
    {
      payload
    }
  );
}

export function getCompanyData(queryParams: CompanyDataRequest) {
  return call_get<ApiPaginatedResponse<CompanyDataModel>>(
    MONITORING_LIST_API.COMPANY_DATA(queryParams.vendorCompanyId!),
    getMultiSortParams(queryParams)
  );
}

export function getCompanyReport(companyId: string) {
  return call_get<MonitoredCompanyReportingStateResponse>(
    MONITORING_LIST_API.COMPANY_DATA_REPORTS(companyId)
  );
}

export function requestCompanyReport(companyId: string) {
  return call_put<MonitoredCompanyReportingStateResponse>(
    MONITORING_LIST_API.COMPANY_DATA_REPORTS(companyId)
  );
}
