import { ColDef, ITooltipParams } from "ag-grid-community";
import i18next from "i18next";

import { AdoptionRenderer } from "../../Components/CellComponents/AdoptionRenderer/AdoptionRenderer";
import { AlternativeCompaniesRenderer } from "../../Components/CellComponents/AlternativeCompaniesRenderer/AlternativeCompaniesRenderer";
import { ChurnRiskRenderer } from "../../Components/CellComponents/ChurnRiskRenderer/ChurnRiskRenderer";
import { CompanyDataRenderer } from "../../Components/CellComponents/CompanyDataRenderer/CompanyDataRenderer";
import { CustomersRenderer } from "../../Components/CellComponents/CustomersRenderer/CustomersRenderer";
import { LogoCompanyNameRenderer } from "../../Components/CellComponents/LogoCompanyNameRenderer/LogoCompanyNameRenderer";
import { MonitoringStatusSelectRenderer } from "../../Components/CellComponents/MonitoringStatusSelectRenderer/MonitoringStatusSelectRenderer";
import { NPSRenderer } from "../../Components/CellComponents/NPSRenderer/NPSRenderer";
import { RenewalRenderer } from "../../Components/CellComponents/RenewalRenderer/RenewalRenderer";
import { SpendRenderer } from "../../Components/CellComponents/SpendRenderer/SpendRenderer";
import { ColumnHeader } from "../../Components/ColumnHeader/ColumnHeader";
import { ExportCSVButton } from "../../Components/ExportCSVButton/ExportCSVButton";

import styles from "./UserMonitoringListTable.module.scss";

const maxWidth = 100;

export const getUserColumns = (clientUserId: string): ColDef[] => [
  {
    field: "vendorCompanyName",
    headerName: i18next.t("userMonitoringListPage:targetName"),
    cellRenderer: LogoCompanyNameRenderer,
    tooltipValueGetter: ({ data }: ITooltipParams) => data.vendorCompanyName
  },
  {
    field: "customersCount",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:customers")}
        tooltip={i18next.t("userMonitoringListPage:customersTooltip")}
      />
    ),
    maxWidth,
    cellRenderer: CustomersRenderer
  },
  {
    field: "churnRisk",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:churnRisk")}
        tooltip={i18next.t("userMonitoringListPage:churnRiskTooltip")}
      />
    ),
    cellRenderer: ChurnRiskRenderer,
    maxWidth
  },
  {
    field: "nps",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:nps")}
        tooltip={i18next.t("userMonitoringListPage:npsTooltip")}
      />
    ),
    cellRenderer: NPSRenderer,
    maxWidth
  },
  {
    field: "spend",
    cellRenderer: SpendRenderer,
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:spend")}
        tooltip={i18next.t("userMonitoringListPage:spendTooltip")}
      />
    ),
    maxWidth
  },
  {
    field: "renewalIntent",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:renewal")}
        tooltip={i18next.t("userMonitoringListPage:renewalTooltip")}
      />
    ),
    cellRenderer: RenewalRenderer,
    maxWidth
  },
  {
    field: "adoption",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:adoption")}
        tooltip={i18next.t("userMonitoringListPage:adoptionTooltip")}
      />
    ),
    cellRenderer: AdoptionRenderer,
    maxWidth
  },
  {
    field: "alternativeCompanies",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:alternatives")}
        tooltip={i18next.t("userMonitoringListPage:alternativesTooltip")}
      />
    ),
    cellRenderer: AlternativeCompaniesRenderer,
    flex: 1
  },
  {
    field: "status",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:status")}
        tooltip={i18next.t("userMonitoringListPage:statusTooltip")}
      />
    ),
    cellRenderer: MonitoringStatusSelectRenderer,
    cellRendererParams: { clientUserId },
    maxWidth: 160
  },
  {
    flex: 1,
    cellRenderer: CompanyDataRenderer,
    headerComponent: ExportCSVButton,
    headerComponentParams: {
      tableName: i18next.t("userMonitoringListPage:monitoringList")
    },
    headerClass: styles.companyDataRendererHeader,
    pinned: "right"
  }
];
