import { App } from "antd";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  DefaultToasterService,
  MonitoredCompanyReportingStateResponse,
  MonitoringListService
} from "@arbolus-technologies/api";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { StrongText } from "@arbolus-technologies/ui/components";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";
import { RequestConfirmationContent } from "../Components/RequestConfirmationContent/RequestConfirmationContent";

export function useReport(companyId: string, companyName: string) {
  const [report, setReport] =
    useState<MonitoredCompanyReportingStateResponse>();

  const { modal } = App.useApp();
  const { t } = useTranslation("userMonitoringListPage");
  const currentUser = useSelector(CacheSelector.currentUserSelector());
  const { trackClick } = useArbolusTracking();

  const fetchReport = useCallback(
    function () {
      if (!companyId) return;

      MonitoringListService.getCompanyReport(companyId)
        .then(setReport)
        .catch((error) => {
          DefaultToasterService.showApiErrors(error);
          console.error(error);
        });
    },
    [companyId]
  );

  const openReportRequestModal = () => {
    if (report?.companyReportRequestDate) {
      DefaultToasterService.showInfo(
        `This report already exists: ${JSON.stringify(report?.companyReportRequestDate)}`
      );
      return;
    }

    modal.confirm({
      title: t("requestModalTitle", { companyName }),
      content: <RequestConfirmationContent />,
      okText: t("requestModalOkButton"),
      onOk: handleRequestReport,
      icon: null,
      closable: true,
      width: 480
    });
  };

  function handleRequestReport() {
    requestReport()
      .then(() => {
        showThankYou();
        trackClick(MixPanelEventNames.MonitoringListCompanyDataReportRequest);
      })
      .catch((error) => {
        console.error(error);
        DefaultToasterService.showApiErrors(error);
      });
  }

  const requestReport = useCallback(async () => {
    await MonitoringListService.requestCompanyReport(companyId);
    setReport({
      companyReportRequestDate: new Date().toISOString(),
      approvedInsightsCount: report?.approvedInsightsCount || 0,
      approvedInsightsMin: report?.approvedInsightsMin || 10,
      userRequestedReportsMax: report?.userRequestedReportsMax || 2,
      userRequestedReportsCount: (report?.userRequestedReportsCount || 0) + 1
    });
  }, []);

  function showThankYou() {
    modal.info({
      title: t("requestThanksTitle"),
      content: (
        <Trans
          i18nKey="userMonitoringListPage:requestThanksContent"
          // @ts-ignore
          components={{ b: <StrongText /> }}
          values={{ email: currentUser.email }}
        />
      ),
      icon: null
    });
  }

  return { report, fetchReport, openReportRequestModal };
}
