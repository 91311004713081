import { CanopyExpertApprovalStats } from "@arbolus-technologies/models/canopy";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { Flex, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { WarningCheck } from "./WarningCheck";

const { Text } = Typography;

interface ComplianceWarningBodyProps {
  canopyExpertApprovalStats: CanopyExpertApprovalStats;
}

export const ComplianceWarningBody: React.FC<ComplianceWarningBodyProps> = ({
  canopyExpertApprovalStats
}) => {
  const { t } = useTranslation("complianceWarning");

  return (
    <Flex vertical gap={8}>
      <Text>{t("complianceWarningDescription")}</Text>
      <Flex align="center" gap={16}>
        {Object.entries(canopyExpertApprovalStats).map(([key, value]) =>
          value !== null ? (
            <WarningCheck
              key={key}
              iconColor={
                ARBOLUS_COLORS[value ? "bColorSuccess" : "bColorError"]
              }
              iconName={value ? "check" : "error"}
              text={t(key)}
            />
          ) : null
        )}
      </Flex>
    </Flex>
  );
};
