import { Alert, Divider, Flex, Skeleton, Typography } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { RepeatedEngagementDetails } from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { RepeatedEngagementEvent } from "./RepeatedEngagementEvent";

const { Text } = Typography;

interface RepeatedEngagementProps {
  projectId: string;
  repeatedEngagementDetails: RepeatedEngagementDetails;
}

export const RepeatedEngagement: React.FC<RepeatedEngagementProps> = ({
  projectId,
  repeatedEngagementDetails
}) => {
  const { t } = useTranslation("referralCompliance");

  const loggedInUser = useSelector(CacheSelector.loggedInUser());

  if (!repeatedEngagementDetails) {
    return <Skeleton paragraph />;
  }

  const { totalEngagements, timeFrame, pendingEngagements, pastEngagements } =
    repeatedEngagementDetails;

  const sortedPendingEngagements = [...pendingEngagements].sort((a, b) => {
    const aIsMatch = a.projectId === projectId;
    const bIsMatch = b.projectId === projectId;

    if (aIsMatch === bIsMatch) {
      return 0;
    }
    return aIsMatch ? -1 : 1;
  });

  const isComplianceManager = !!loggedInUser.complianceManager;
  const hasPendingEngagements = pendingEngagements.length > 0;

  return (
    <Flex vertical gap={16}>
      {isComplianceManager && hasPendingEngagements && (
        <Alert
          message={t("repeatedEngagementWarning", {
            totalEngagements,
            timeFrame
          })}
          type="warning"
          showIcon
          style={{ alignItems: "flex-start" }}
        />
      )}
      {sortedPendingEngagements.map((repeatedEngagementEventDetails, index) => (
        <Fragment key={repeatedEngagementEventDetails.eventId}>
          <RepeatedEngagementEvent
            engagementNumber={
              pendingEngagements.length + pastEngagements.length - index
            }
            isPendingForComplianceManager={
              isComplianceManager &&
              repeatedEngagementEventDetails.projectId === projectId
            }
            repeatedEngagementEventDetails={repeatedEngagementEventDetails}
          />
          <Divider style={{ margin: 0 }} />
        </Fragment>
      ))}
      {pastEngagements.length > 0 && (
        <>
          <Text strong>{t("pastEngagements")}</Text>
          {pastEngagements.map((repeatedEngagementEventDetails, index) => (
            <Fragment key={repeatedEngagementEventDetails.eventId}>
              <RepeatedEngagementEvent
                engagementNumber={pastEngagements.length - index}
                repeatedEngagementEventDetails={repeatedEngagementEventDetails}
              />
              {index < pastEngagements.length - 1 && (
                <Divider style={{ margin: 0 }} />
              )}
            </Fragment>
          ))}
        </>
      )}
    </Flex>
  );
};
