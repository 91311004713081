import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { AntDIcon } from "@arbolus-technologies/antDComponents";
import { DefaultToasterService } from "@arbolus-technologies/api";
import { MONITORING_LIST } from "@arbolus-technologies/routes";
import { BASE_URL } from "@arbolus-technologies/utils";

export function ShareCompanyButton({ companyId }: { companyId: string }) {
  const { t } = useTranslation("userMonitoringListPage");

  function putLinkInClipboard() {
    const link = `${BASE_URL}${MONITORING_LIST}/?sharedCompanyId=${companyId}`;
    navigator.clipboard.writeText(link);
    DefaultToasterService.showSuccess(t("shareSuccess"));
  }

  return (
    <Button icon={<AntDIcon name="copy" />} onClick={putLinkInClipboard}>
      {t("shareButton")}
    </Button>
  );
}
