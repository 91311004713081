import { Divider, Flex, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDAvatar, AntDIcon } from "@arbolus-technologies/antDComponents";
import { Compliance } from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  REFERRAL_COMPLIANCE_STATE,
  REFERRAL_SUB_STATE
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import { COMPLIANCE_UPDATE_FORMAT } from "@arbolus-technologies/utils";

import { ReferralComplianceProps } from "./Compliance";
import { ComplianceAnswerForm } from "./ComplianceAnswerForm";

import styles from "./Compliance.module.scss";

const { Text } = Typography;
const complianceAutoApproval = "Compliance automatically approved";

interface ComplianceAnswersProps {
  expertId: string;
  referral: ReferralComplianceProps;
  complianceInfo: Compliance[];
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  onUpdateComplianceAnswers: (answers: Compliance[]) => void;
}

export const ComplianceAnswers: React.FC<ComplianceAnswersProps> = ({
  expertId,
  referral,
  complianceInfo,
  doNotContactStatus,
  onUpdateComplianceAnswers
}) => {
  const { t } = useTranslation("referralCompliance");

  const isAdmin = useSelector(CacheSelector.isAdmin());

  const {
    application,
    compliance: {
      subStatus: complianceStatus,
      updateUser: complianceUpdatedBy,
      updateDate: complianceUpdateDate
    },
    chaperoneCall = false,
    complianceNote
  } = referral;

  const isApplicationPending =
    application.subStatus === REFERRAL_SUB_STATE.PENDING;

  const isCompliancePending =
    complianceStatus === REFERRAL_COMPLIANCE_STATE.PENDING;
  const isCompliancePassed =
    complianceStatus === REFERRAL_COMPLIANCE_STATE.ACCEPT;

  const isAutomaticallyApproved = complianceNote === complianceAutoApproval;

  const firstCallStatus = chaperoneCall
    ? t("joiningFirstCall")
    : t("notJoiningFirstCall");

  return !isAdmin ||
    doNotContactStatus === DO_NOT_CONTACT_STATUS.DNC ||
    !isApplicationPending ? (
    <Flex vertical>
      <ol className={styles.complianceList}>
        {complianceInfo.map((item) => {
          const expectedAnswer = item.question.displayModel.expectedAnswer;
          const answer = item.answer?.answer;
          const textAnswer = item.answer?.textAnswer;
          const isAnswerMatch = !expectedAnswer || expectedAnswer === answer;

          return (
            <li key={item.questionId}>
              <Flex gap={8} vertical>
                <Text strong>{item.question.question}</Text>
                <Flex gap={16} align="center">
                  {answer && (
                    <Tag
                      icon={
                        <AntDIcon
                          name={isAnswerMatch ? "check_circle" : "cancel"}
                        />
                      }
                      color={isAnswerMatch ? "success" : "error"}
                      className={styles.tag}
                    >
                      {answer}
                    </Tag>
                  )}
                  <Text>{t("preferredAnswer", { expectedAnswer })}</Text>
                  {textAnswer && <Text>{t("detailsRequired")}</Text>}
                </Flex>
                {textAnswer && <Text>{textAnswer}</Text>}
              </Flex>
            </li>
          );
        })}
      </ol>
      {!isCompliancePending && (
        <>
          <Divider />
          <Flex gap={16} vertical>
            <Flex gap={16} align="center" justify="space-between">
              <Text strong>{t("complianceApproval")}</Text>
              <Flex gap={8}>
                <Tag
                  icon={
                    <AntDIcon
                      name={isCompliancePassed ? "check_circle" : "cancel"}
                    />
                  }
                  color={isCompliancePassed ? "success" : "error"}
                  className={styles.tag}
                >
                  {isCompliancePassed ? t("passed") : t("failed")}
                </Tag>
                {complianceUpdatedBy && isCompliancePassed && (
                  <Tag
                    icon={
                      <AntDIcon
                        name={chaperoneCall ? "call" : "phone_disabled"}
                      />
                    }
                    color={chaperoneCall ? "processing" : "default"}
                    className={styles.tag}
                  >
                    {firstCallStatus}
                  </Tag>
                )}
              </Flex>
            </Flex>
            {!isAutomaticallyApproved && !isCompliancePassed && (
              <Flex gap={8} vertical>
                <Text strong>{t("additionalComments")}</Text>
                <Text>{complianceNote ?? "-"}</Text>
              </Flex>
            )}
            {complianceUpdatedBy && (
              <Flex gap={8} align="center" justify="space-between">
                {isAutomaticallyApproved ? (
                  <Text>{t("automaticallyApproved")}</Text>
                ) : (
                  <AntDAvatar
                    profileImageUrl={complianceUpdatedBy.profileImageUrl}
                    firstName={complianceUpdatedBy.firstName}
                    lastName={complianceUpdatedBy.lastName}
                    size="small"
                  />
                )}
                <Text code>
                  {moment(complianceUpdateDate).format(
                    COMPLIANCE_UPDATE_FORMAT
                  )}
                </Text>
              </Flex>
            )}
          </Flex>
        </>
      )}
    </Flex>
  ) : (
    <ComplianceAnswerForm
      complianceInfo={complianceInfo}
      expertId={expertId}
      onUpdateComplianceAnswers={onUpdateComplianceAnswers}
    />
  );
};
