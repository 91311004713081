import { GridApi } from "ag-grid-community";
import { Alert, Button, Card, Flex, Typography } from "antd";
import { useTranslation } from "react-i18next";

import {
  MixPanelEventNames,
  useArbolusTracking
} from "@arbolus-technologies/features/common";

import { UserMonitoringListTable } from "../../Modules/UserMonitoringListTable/UserMonitoringListTable";
import { useAutoAddCompany } from "./useAutoAddCompany";

import styles from "./UserMonitoringListPage.module.scss";

interface UserMonitoringListTableProps {
  userId: string;
  showModal: () => void;
  setNumOfCompanies: (numOfCompanies: number) => void;
  onGridApiReady?: (api: GridApi) => void;
}

export function UserMonitoringListTablePage({
  userId,
  showModal,
  setNumOfCompanies,
  onGridApiReady
}: UserMonitoringListTableProps) {
  const { t } = useTranslation("userMonitoringListPage");
  const { trackClick } = useArbolusTracking();
  const addedCompany = useAutoAddCompany();

  return (
    <>
      {addedCompany && (
        <Alert
          message={t("companyAddedSuccess", { addedCompany })}
          type="success"
          showIcon
          closable
        />
      )}

      <Card className={styles.card}>
        <Flex justify="space-between" align="center">
          <Typography.Title level={5}>
            {t("addCompaniesTitle")}
          </Typography.Title>
          <Button
            type="primary"
            onClick={() => {
              showModal();
              trackClick(MixPanelEventNames.MonitoringListAddCompany);
            }}
          >
            {t("addCompanies")}
          </Button>
        </Flex>
      </Card>
      <UserMonitoringListTable
        onGridApiReady={onGridApiReady}
        userId={userId}
        setNumOfCompanies={setNumOfCompanies}
      />
    </>
  );
}
