import { GridApi } from "ag-grid-community";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CIQError,
  CompanyService,
  DefaultToasterService,
  ErrorResponse,
  GetUserCompaniesResponse,
  LIST_COMPANIES_ORDER_BY,
  SORT_DIRECTION,
  UserCompanyItem
} from "@arbolus-technologies/api";
import { useAddCompanies } from "./useAddCompany";

const queryParams = {
  sort: [
    {
      orderBy: LIST_COMPANIES_ORDER_BY.Name,
      orderDirection: SORT_DIRECTION.ASCENDING
    }
  ]
};
export function useCompaniesRequests(
  userId: string,
  searchTerm: string,
  gridApi?: GridApi
) {
  const callAddCompanies = useAddCompanies();
  const [companiesList, setCompaniesList] = useState<UserCompanyItem[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation("addCompaniesModal");

  const getCompanies = useCallback(() => {
    setIsLoading(true);
    CompanyService.listCompaniesByUser(userId, {
      ...queryParams,
      limit: searchTerm ? 100 : 10,
      searchTerm,
      offset: searchTerm ? 0 : companiesList.length + offset,
      sorting: ["CompanySignalsCount,desc", "InsightsCount,desc"]
    })
      .then((res: GetUserCompaniesResponse) => {
        setCompaniesList((prevCompanies) => [...prevCompanies, ...res.items]);
        setTotalCount(res.pagination.count);
        if (searchTerm) {
          setOffset(res.pagination.offset);
        }
        setIsLoading(false);
      })
      .catch((error: ErrorResponse<CIQError>) => {
        DefaultToasterService.showApiErrors(error);
        setIsLoading(false);
      });
  }, [userId, searchTerm, companiesList, offset]);

  const addCompanies = async (selectedCompanies: UserCompanyItem[]) => {
    setIsLoading(true);
    try {
      await callAddCompanies(selectedCompanies.map((company) => company.id));
      DefaultToasterService.showSuccess(t("companiesAddedSuccess"));
      gridApi?.refreshServerSide({ purge: true });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    companiesList,
    isLoading,
    totalCount,
    getCompanies,
    resetCompanies: () => {
      setCompaniesList([]);
      setOffset(0);
    },
    addCompanies
  };
}
