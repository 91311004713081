import { ColDef, ITooltipParams } from "ag-grid-community";
import i18next from "i18next";

import { AdoptionRenderer } from "../../Components/CellComponents/AdoptionRenderer/AdoptionRenderer";
import { ChurnRiskRenderer } from "../../Components/CellComponents/ChurnRiskRenderer/ChurnRiskRenderer";
import { CompetitorsNamesRenderer } from "../../Components/CellComponents/CompetitorsCompaniesRenderer/CompetitorsCompaniesRenderer";
import { LastUpdateRenderer } from "../../Components/CellComponents/LastUpdateRenderer/LastUpdateRenderer";
import { LogoCompanyNameRenderer } from "../../Components/CellComponents/LogoCompanyNameRenderer/LogoCompanyNameRenderer";
import { NPSCompanyDataRenderer } from "../../Components/CellComponents/NPSRenderer/NPSCompanyDataRenderer";
import { PersonaRenderer } from "../../Components/CellComponents/PersonaRenderer/PersonaRenderer";
import { RenewalRenderer } from "../../Components/CellComponents/RenewalRenderer/RenewalRenderer";
import { SpendRenderer } from "../../Components/CellComponents/SpendRenderer/SpendRenderer";
import { ColumnHeader } from "../../Components/ColumnHeader/ColumnHeader";
import { ExportCSVButton } from "../../Components/ExportCSVButton/ExportCSVButton";

import styles from "./CompanyDataTable.module.scss";

const maxWidth = 100;

export const COLUMN_DEFS: ColDef[] = [
  {
    field: "customerCompanyName",
    headerName: i18next.t("companyData:customersCount"),
    cellRenderer: LogoCompanyNameRenderer,
    tooltipValueGetter: ({ data }: ITooltipParams) => data.customerCompanyName
  },
  {
    field: "churnRisk",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:churnRisk")}
        tooltip={i18next.t("userMonitoringListPage:churnRiskTooltip")}
      />
    ),
    cellRenderer: ChurnRiskRenderer,
    maxWidth
  },
  {
    field: "persona",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("companyData:user")}
        tooltip={i18next.t("companyData:userTooltip")}
      />
    ),
    cellRenderer: PersonaRenderer,
    maxWidth
  },
  {
    field: "nps",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:nps")}
        tooltip={i18next.t("userMonitoringListPage:npsTooltip")}
      />
    ),
    cellRenderer: NPSCompanyDataRenderer,
    maxWidth: 80
  },
  {
    field: "spend",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:spend")}
        tooltip={i18next.t("userMonitoringListPage:spendTooltip")}
      />
    ),
    cellRenderer: SpendRenderer,
    maxWidth
  },
  {
    field: "renewalIntent",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:renewal")}
        tooltip={i18next.t("userMonitoringListPage:renewalTooltip")}
      />
    ),
    cellRenderer: RenewalRenderer,
    maxWidth
  },
  {
    field: "adoption",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("userMonitoringListPage:adoption")}
        tooltip={i18next.t("userMonitoringListPage:adoptionTooltip")}
      />
    ),
    cellRenderer: AdoptionRenderer,
    width: 140
  },
  {
    field: "competitorsNames",
    headerComponent: () => (
      <ColumnHeader
        text={i18next.t("companyData:evaluated")}
        tooltip={i18next.t("companyData:evaluatedTooltip")}
      />
    ),
    cellRenderer: CompetitorsNamesRenderer,
    flex: 1
  },
  {
    field: "lastUpdate",
    headerComponent: ExportCSVButton,
    headerComponentParams: {
      tableName: i18next.t("companyData:companyData")
    },
    cellRenderer: LastUpdateRenderer,
    flex: 1,
    maxWidth: 180,
    headerClass: styles.lastUpdateHeader,
    pinned: "right"
  }
];
