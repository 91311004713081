import clsx from "clsx";
import React from "react";

import { COMPLIANCE_STEPS } from "@arbolus-technologies/models/canopy-panels";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { CircleWithIcon } from "@arbolus-technologies/ui/components";

import { ComplianceBoxButtons } from "./ComplianceBoxButtons/ComplianceBoxButtons";

import styles from "./ComplianceBox.module.scss";

export interface ComplianceBoxProps {
  status: COMPLIANCE_STEPS;
  title: string;
  subtitle: string;
  disabled: boolean;
  disableApproveButton: boolean;
  handleNext: (status: COMPLIANCE_STEPS) => void;
}

export const ComplianceBox: React.FC<ComplianceBoxProps> = ({
  status,
  title,
  subtitle,
  disabled,
  disableApproveButton,
  handleNext
}) => {
  const isApproved = status === COMPLIANCE_STEPS.APPROVED;
  const isRejected = status === COMPLIANCE_STEPS.REJECTED;
  const isApprovedOrRejected = isApproved || isRejected;

  return (
    <div
      className={clsx(styles.container, {
        [styles.approved]: isApproved,
        [styles.rejected]: isRejected
      })}
    >
      <div className={styles.iconTextContainer}>
        {isApprovedOrRejected && (
          <CircleWithIcon
            icon={isApproved ? "check" : "close"}
            background={
              isApproved
                ? ARBOLUS_COLORS.bColorSecondaryGreen
                : ARBOLUS_COLORS.bColorBaseOrange
            }
            color={ARBOLUS_COLORS.bColorBaseWhite}
            customHeight="48px"
            customWidth="48px"
            fontSize="24px"
          />
        )}
        <div className={styles.textContainer}>
          <h1 className={styles.title}>{title}</h1>
          <h2 className={styles.subtitle}>{subtitle}</h2>
        </div>
      </div>
      {!isApprovedOrRejected && (
        <ComplianceBoxButtons
          handleNext={handleNext}
          status={status}
          disabled={disabled}
          disableApproveButton={disableApproveButton}
        />
      )}
    </div>
  );
};
