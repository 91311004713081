import { AppSelector as AppSelectorNx } from "@arbolus-technologies/stores/app";
import React from "react";
import { useSelector } from "react-redux";
import styles from "./AuthPageBase.module.scss";
import { DefaultBanner } from "./DefaultBanner/DefaultBanner";
import { WebinarBanner } from "./WebinarBanner/WebinarBanner";

export function AuthPageBase({
  children
}: {
  children: React.ReactNode;
}): React.ReactElement {
  const { WebinarBanner: webinarFF } = useSelector(
    AppSelectorNx.getFeatureFlags()
  );

  return (
    <div className={styles.wrapper}>
      <div>{children}</div>
      <div className={styles.banner}>
        {webinarFF ? <WebinarBanner /> : <DefaultBanner />}
      </div>
    </div>
  );
}
