import { Col, Flex, Form, FormInstance, Row } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";

import {
  BasicUser,
  GetEventResponse,
  Project,
  ReferralDetail
} from "@arbolus-technologies/api";
import {
  DefaultTimezone,
  EventGuestOption
} from "@arbolus-technologies/models/common";
import {
  EVENT_FORM,
  EventFormInterface
} from "@arbolus-technologies/models/project";
import { TimezoneService } from "@arbolus-technologies/utils";

import { EventAttachments } from "./Sections/EventAttachments/EventAttachments";
import { EventDetails } from "./Sections/EventDetails";
import { EventTranscriptOptions } from "./Sections/EventTranscriptOptions";
import { FormActions } from "./Sections/FormActions";

dayjs.extend(utc);

const {
  ATTACHMENTS,
  STARTING_DATE,
  ENDING_DATE,
  ENDING_TIME,
  FREE_TRANSCRIPT,
  GUESTS,
  NOTES,
  SMART_TRANSCRIPT,
  STARTING_TIME,
  TIMEZONE,
  TITLE
} = EVENT_FORM;

interface EventFormProps {
  form: FormInstance<EventFormInterface>;
  isSaving: boolean;
  project: Project;
  eventDetails: GetEventResponse | null;
  currentBasicUser: BasicUser;
  referral: ReferralDetail | null;
  handleSubmit: (values: EventFormInterface) => void;
  onFinishFailed: () => void;
  defaultEventGuests: EventGuestOption[] | undefined;
  defaultTimezone: DefaultTimezone | undefined;
  isEditMode: boolean;
}

export const EventForm: React.FC<EventFormProps> = ({
  form,
  isSaving,
  project,
  eventDetails,
  currentBasicUser,
  referral,
  handleSubmit,
  onFinishFailed,
  defaultEventGuests,
  defaultTimezone,
  isEditMode
}) => (
  <Form
    form={form}
    layout="vertical"
    scrollToFirstError
    initialValues={{
      [TITLE]: eventDetails?.title,
      [STARTING_DATE]: TimezoneService.getEventTimezoneSpecificValue(
        eventDetails?.startTime,
        eventDetails?.timezone?.id
      ),
      [ENDING_DATE]: TimezoneService.getEventTimezoneSpecificValue(
        eventDetails?.endTime,
        eventDetails?.timezone?.id
      ),
      [STARTING_TIME]: TimezoneService.getEventTimezoneSpecificValue(
        eventDetails?.startTime,
        eventDetails?.timezone?.id
      ),
      [ENDING_TIME]: TimezoneService.getEventTimezoneSpecificValue(
        eventDetails?.endTime,
        eventDetails?.timezone?.id
      ),
      [TIMEZONE]: defaultTimezone,
      [GUESTS]: defaultEventGuests,
      [NOTES]: eventDetails?.notes,
      [FREE_TRANSCRIPT]: eventDetails?.transcribe ?? true,
      [SMART_TRANSCRIPT]: eventDetails?.humanTranscribe ?? false,
      [ATTACHMENTS]: eventDetails?.eventAttachments ?? []
    }}
    onFinish={handleSubmit}
    onFinishFailed={onFinishFailed}
    disabled={isSaving}
  >
    <Row gutter={24}>
      <Col span={16}>
        <Flex vertical gap={24}>
          <EventDetails
            project={project}
            organizer={eventDetails?.organizer}
            currentBasicUser={currentBasicUser}
            referral={referral}
          />
          <FormActions isEditMode={isEditMode} isSaving={isSaving} />
        </Flex>
      </Col>

      <Col span={8}>
        <Flex vertical gap={24}>
          <EventTranscriptOptions />
          <EventAttachments projectId={project.id} />
        </Flex>
      </Col>
    </Row>
  </Form>
);
