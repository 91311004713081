import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DefaultToasterService, MetaService } from "@arbolus-technologies/api";
import { getQueryParams } from "@arbolus-technologies/utils";

import { useAddCompanies } from "../../Components/AddCompaniesModal/useAddCompany";

export function useAutoAddCompany(): string | undefined {
  const { t } = useTranslation("userMonitoringListPage");
  const addCompanies = useAddCompanies();
  const [addedCompany, setAddedCompany] = useState<string>();

  useEffect(() => {
    const params = getQueryParams();
    if (!params.sharedCompanyId) return;
    addCompany(params.sharedCompanyId);
  }, []);

  async function addCompany(companyId: string) {
    try {
      // Allow only small letters, numbers and dash (UUID)
      if (!/^[a-z0-9-]+$/.test(companyId)) {
        throw new Error(t("invalidCompanyId"));
      }

      const companyDetails =
        await MetaService.getCompanyById(companyId).toPromise();

      await addCompanies([companyId]);
      setAddedCompany(companyDetails.name);

      // Remove the URL parameter to avoid adding it again etc.
      window.history.replaceState(null, "", window.location.pathname);
    } catch (error) {
      DefaultToasterService.showApiErrors(error);
      console.error(error);
    }
  }

  return addedCompany;
}
