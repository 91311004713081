import { Form } from "antd";
import { useTranslation } from "react-i18next";

import { Project } from "@arbolus-technologies/api";
import {
  EVENT_FORM,
  EventFormInterface,
  SelectedSlot
} from "@arbolus-technologies/models/project";
import {
  EVENT_SLOT_FORMAT,
  MAX_DAYS,
  MINUTE_STEP
} from "@arbolus-technologies/utils";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useEffect, useState } from "react";

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

const { STARTING_DATE, ENDING_DATE, ENDING_TIME, STARTING_TIME } = EVENT_FORM;

interface UseEventDate {
  handleSetStartingDate: (date: Dayjs | null) => void;
  handleSetEndingDate: (date: Dayjs | null) => void;
  handleSetStartingTime: (date: Dayjs | null) => void;
  handleSetEndingTime: (date: Dayjs | null) => void;
  disabledStartDate: (current: Dayjs) => boolean;
  disabledEndDate: (current: Dayjs) => boolean;
  selectedSlot: string | null;
  handleSelectSlot: (slot: SelectedSlot) => void;
}

interface UseEventDateProps {
  project: Project;
  startingDate: string | null;
  endingDate: string | null;
}

export const useEventDate = ({
  project,
  startingDate,
  endingDate
}: UseEventDateProps): UseEventDate => {
  const { t } = useTranslation("eventForm");

  const form = Form.useFormInstance<EventFormInterface>();
  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);

  const convertToUtcAndFormatToSlot = (utcDate: string): string => {
    return dayjs.utc(utcDate).format(EVENT_SLOT_FORMAT);
  };

  // Assign date from URL parameters to available slots and selectors
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!startingDate || startingDate === "undefined") return;

    const validEndingDate =
      endingDate && endingDate !== "undefined"
        ? endingDate
        : dayjs.utc(startingDate).add(1, "hour").toISOString();

    const startTime = convertToUtcAndFormatToSlot(startingDate);
    const endTime = convertToUtcAndFormatToSlot(validEndingDate);

    handleSelectSlot({ startTime, endTime });
  }, [startingDate, endingDate]);

  const handleSetStartingDate = (date: Dayjs | null) => {
    if (!date) return;

    form.setFieldsValue({
      [STARTING_DATE]: date.startOf("day"),
      [ENDING_DATE]: date.startOf("day")
    });

    // Retrigger validation for STARTING_TIME
    const startingTime = form.getFieldValue(STARTING_TIME);
    if (startingTime) {
      handleSetStartingTime(startingTime);
    }
  };

  const handleSetEndingDate = (date: Dayjs | null) => {
    if (!date) return;

    form.setFieldsValue({
      [ENDING_DATE]: date.startOf("day")
    });

    // Retrigger validation for ENDING_TIME
    const endingTime = form.getFieldValue(ENDING_TIME);
    if (endingTime) {
      handleSetEndingTime(endingTime);
    }
  };

  const handleSetStartingTime = (date: Dayjs | null) => {
    if (!date) return;

    const startingDate = form.getFieldValue(STARTING_DATE);

    if (startingDate) {
      const selectedStartDateTime = dayjs(startingDate)
        .hour(date.hour())
        .minute(date.minute());
      const currentTime = dayjs();

      // Check if the selected start date-time is before the current time
      if (selectedStartDateTime.isBefore(currentTime, "minute")) {
        form.setFields([
          {
            name: STARTING_TIME,
            value: date,
            errors: [t("eventStartingTimeError")]
          }
        ]);
        return;
      }

      const endingDateTime = selectedStartDateTime.add(1, "hour");
      const endingDate =
        endingDateTime.date() !== selectedStartDateTime.date()
          ? startingDate.add(1, "day").startOf("day")
          : startingDate;

      form.setFields([
        {
          name: STARTING_TIME,
          value: date,
          errors: []
        },
        {
          name: ENDING_DATE,
          value: endingDate
        },
        {
          name: ENDING_TIME,
          value: endingDateTime,
          errors: []
        }
      ]);
    }
  };

  const handleSetEndingTime = (date: Dayjs | null) => {
    if (!date) return;

    const startingTime = form.getFieldValue(STARTING_TIME);
    const startingDate = form.getFieldValue(STARTING_DATE);
    const endingDate = form.getFieldValue(ENDING_DATE);

    if (!startingTime || !startingDate || !endingDate) {
      form.setFields([
        {
          name: ENDING_TIME,
          value: date,
          errors: []
        }
      ]);
      return;
    }

    const startingDateTime = dayjs(startingDate)
      .hour(startingTime.hour())
      .minute(startingTime.minute());

    const endingDateTime = dayjs(endingDate)
      .hour(date.hour())
      .minute(date.minute());

    // Validate that ending time is not before the starting time
    const errors = endingDateTime.isBefore(
      startingDateTime.add(MINUTE_STEP, "minute")
    )
      ? [t("eventEndingTimeError")]
      : [];

    form.setFields([
      {
        name: ENDING_TIME,
        value: date,
        errors: errors
      }
    ]);
  };

  const disabledStartDate = (current: Dayjs) => {
    return current && current.isBefore(dayjs().startOf("day"));
  };

  const disabledEndDate = (current: Dayjs) => {
    const startingDate = form.getFieldValue(STARTING_DATE);
    if (!startingDate) {
      return current && current.isBefore(dayjs().startOf("day"));
    }

    return (
      current.isBefore(startingDate) ||
      current.isAfter(startingDate.add(MAX_DAYS - 1, "day")) // this is to include the starting date
    );
  };

  const handleSelectSlot = (slot: SelectedSlot) => {
    setSelectedSlot(slot.startTime);

    const startTime = dayjs.utc(slot.startTime).tz(project.timezone);
    const endTime = dayjs.utc(slot.endTime).tz(project.timezone);

    // Update form fields with selected slot data
    form.setFieldsValue({
      [STARTING_DATE]: startTime,
      [STARTING_TIME]: startTime,
      [ENDING_DATE]: endTime,
      [ENDING_TIME]: endTime
    });
  };

  return {
    handleSetStartingDate,
    handleSetEndingDate,
    handleSetStartingTime,
    handleSetEndingTime,
    disabledStartDate,
    disabledEndDate,
    selectedSlot,
    handleSelectSlot
  };
};
