import { Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forkJoin, of } from "rxjs";
import { catchError } from "rxjs/operators";

import {
  CIQError,
  ComplianceStatus,
  Compliance as ComplianceType,
  DefaultToasterService,
  EngagementService,
  ErrorResponse,
  ExpertDetail,
  ExpertService,
  PROJECT_REFERRAL_STATE,
  ProjectAnswer,
  ProjectAnswersResponse,
  ProjectService,
  ReferralDetail,
  ToasterService
} from "@arbolus-technologies/api";
import { RepeatedEngagementDetails } from "@arbolus-technologies/models/common";
import { OpenExpertProfileParams } from "@arbolus-technologies/models/project";
import { CacheSelector } from "@arbolus-technologies/stores/cache";
import {
  PanelId,
  PanelStoreActions
} from "@arbolus-technologies/stores/panels";
import { ProjectNxSelector } from "@arbolus-technologies/stores/project";
import { Loader } from "@arbolus-technologies/ui/components";

import {
  DefaultRejectFeedbackExpert,
  RejectFeedbackExpert
} from "./../../../../Components/RejectFeedback/RejectFeedbackExpert";
import { REFERRAL_EXPERT_TABS, REFERRAL_EXPERT_TABS_TYPE } from "./../utils";
import { ExpertInfo } from "./ExpertInfo/ExpertInfo";
import { ReferralTableTabsPanel } from "./ReferralTableTabsPanel";
import { Warnings } from "./Warnings";

interface ReferralsTableExpertProfileProps {
  panelInfo: OpenExpertProfileParams;
  showComplianceTab: boolean;
  onClosePanel: () => void;
  onReferralRemoval?: (referralId: string) => void;
  onMoveToNextExpert?: () => void;
  expertService?: typeof ExpertService;
  projectService?: typeof ProjectService;
  engagementService?: typeof EngagementService;
  feedbackExpert?: RejectFeedbackExpert;
  notificationService?: ToasterService;
}

export const ReferralsTableExpertProfile: React.FC<
  ReferralsTableExpertProfileProps
> = ({
  panelInfo,
  showComplianceTab,
  onClosePanel,
  onReferralRemoval,
  onMoveToNextExpert,
  expertService = ExpertService,
  projectService = ProjectService,
  engagementService = EngagementService,
  feedbackExpert = DefaultRejectFeedbackExpert,
  notificationService = DefaultToasterService
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [expertInfo, setExpertInfo] = useState<ExpertDetail | null>(null);
  const [referral, setReferral] = useState<ReferralDetail | null>(null);
  const [screeningInfo, setScreeningInfo] = useState<ProjectAnswer[]>([]);
  const [complianceAnswers, setComplianceAnswers] = useState<ComplianceType[]>(
    []
  );
  const [repeatedEngagementDetails, setRepeatedEngagementDetails] =
    useState<RepeatedEngagementDetails>();
  const [editNote, setEditNote] = useState(false);
  const [activeTab, setActiveTab] = useState<REFERRAL_EXPERT_TABS_TYPE>(
    REFERRAL_EXPERT_TABS.PROFILE
  );
  const [isVerified, setIsVerified] = useState(false);

  const clientId = useSelector(ProjectNxSelector.projectClientId());
  const currentUser = useSelector(CacheSelector.userAdminProfile());

  const { expertId, projectId, referralId, showAvailabilityTab } = panelInfo;

  const handleMoveToAvailabilityTab = () => {
    setActiveTab(REFERRAL_EXPERT_TABS.SCHEDULING);
  };

  useEffect(() => {
    if (showAvailabilityTab) {
      handleMoveToAvailabilityTab();
    }
  }, [showAvailabilityTab]);

  useEffect(() => {
    if (showComplianceTab) {
      setActiveTab(REFERRAL_EXPERT_TABS.COMPLIANCE);
    }
  }, [showComplianceTab]);

  useEffect(() => {
    if (projectId && expertId && referralId && clientId) {
      const promises = [
        expertService.getExpertById({
          expertId,
          clientId,
          projectId,
          includeRateCard: true
        }),
        projectService.getReferral(projectId, referralId),
        expertService
          .getAnswers(expertId, projectId)
          .pipe(catchError(() => of(null))),
        expertService.getComplianceAnswers(expertId, projectId),
        engagementService.getRepeatedEngagementDetails(clientId, expertId).pipe(
          catchError((err: ErrorResponse<CIQError>) => {
            notificationService.showApiErrors(err);
            return of(null);
          })
        )
      ];

      setIsLoading(true);
      forkJoin(promises).subscribe(
        ([
          expert,
          referral,
          screeningInfo,
          complianceAnswers,
          repeatedEngagementDetails
        ]) => {
          const expertDetail = expert as ExpertDetail;
          setExpertInfo(expertDetail);
          setIsVerified(expertDetail.isVerified);

          setReferral(referral as ReferralDetail);

          screeningInfo
            ? setScreeningInfo((screeningInfo as ProjectAnswersResponse).items)
            : setScreeningInfo([]);

          setComplianceAnswers(
            (complianceAnswers as { items: ComplianceType[] }).items
          );

          setRepeatedEngagementDetails(
            repeatedEngagementDetails as RepeatedEngagementDetails
          );

          setIsLoading(false);
        },
        (err: ErrorResponse<CIQError>) => {
          notificationService.showApiErrors(err);
          setIsLoading(false);
        }
      );
    }
  }, [
    expertId,
    projectId,
    referralId,
    clientId,
    expertService,
    projectService,
    engagementService,
    notificationService
  ]);

  if (isLoading) {
    return <Loader isFull />;
  }

  if (!referral || !expertInfo) {
    return null;
  }

  const handleRejectExpert = (): void => {
    onClosePanel();

    feedbackExpert.setData({
      projectId,
      referralId,
      expert: {
        ...expertInfo.user,
        id: "",
        expertId,
        doNotContactStatus: expertInfo.doNotContactStatus
      }
    });
    dispatch(PanelStoreActions.openPanel(PanelId.RejectFeedback));
  };

  const handleSaveNote = (newNote?: string): void => {
    setReferral({
      ...referral,
      note: {
        ...referral.note,
        // @ts-ignore
        note: newNote,
        noteUser: currentUser,
        noteDate: new Date().toISOString()
      }
    });
    setEditNote(false);
  };

  const handleUpdateScreeningAnswers = (answers: ProjectAnswer[]): void => {
    setScreeningInfo(answers);
  };

  const handleUpdateComplianceAnswers = (answers: ComplianceType[]): void => {
    setComplianceAnswers(answers);
  };

  const handleUpdateComplianceStatus = (
    chaperoneCall: boolean,
    complianceNote: string,
    status: PROJECT_REFERRAL_STATE,
    compliance: ComplianceStatus
  ): void =>
    setReferral({
      ...referral,
      status,
      compliance,
      chaperoneCall,
      complianceNote
    });

  return (
    <Flex vertical gap={24}>
      <ExpertInfo
        expert={expertInfo}
        referral={referral}
        isVerified={isVerified}
        projectId={projectId}
        onReferralRemoval={onReferralRemoval}
        onEditNote={() => {
          setActiveTab(REFERRAL_EXPERT_TABS.PROFILE);
          setEditNote(true);
        }}
        onRejectExpert={handleRejectExpert}
        onMoveToAvailabilityTab={handleMoveToAvailabilityTab}
        onMoveToNextExpert={onMoveToNextExpert}
      />

      <Warnings
        expertInfo={expertInfo}
        status={referral.status}
        review={referral.review}
      />

      <ReferralTableTabsPanel
        expertInfo={expertInfo}
        screeningInfo={screeningInfo}
        complianceAnswers={complianceAnswers}
        repeatedEngagementDetails={repeatedEngagementDetails}
        referral={referral}
        activeTab={activeTab}
        editNote={editNote}
        setEditNote={setEditNote}
        onIsVerified={setIsVerified}
        onSaveNote={handleSaveNote}
        onSetActiveTab={setActiveTab}
        onUpdateScreeningAnswers={handleUpdateScreeningAnswers}
        onUpdateComplianceAnswers={handleUpdateComplianceAnswers}
        onUpdateComplianceStatus={handleUpdateComplianceStatus}
      />
    </Flex>
  );
};
