import { Flex, Tooltip, Typography } from "antd";

import { AntDIcon } from "@arbolus-technologies/antDComponents";

import styles from "./ColumnHeader.module.scss";

const { Text } = Typography;

export function ColumnHeader({
  text,
  tooltip
}: { text: string; tooltip: string }) {
  return (
    <Tooltip title={tooltip}>
      <Flex gap={4}>
        <Text type="secondary" className={styles.headerText}>
          {text}
        </Text>
        <AntDIcon name="info" />
      </Flex>
    </Tooltip>
  );
}
