import { Flex } from "antd";
import { useFeature } from "flagged";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AntDCollapse } from "@arbolus-technologies/antDComponents";
import {
  ComplianceStatus,
  Compliance as ComplianceType,
  PROJECT_REFERRAL_STATE,
  ReferralDetail
} from "@arbolus-technologies/api";
import {
  DO_NOT_CONTACT_STATUS,
  FEATURE_FLAGS,
  REFERRAL_COMPLIANCE_STATE,
  RepeatedEngagementDetails
} from "@arbolus-technologies/models/common";
import { CacheSelector } from "@arbolus-technologies/stores/cache";

import { ComplianceAnswers } from "./ComplianceAnswers";
import { ComplianceQuestionsApproval } from "./ComplianceQuestionsApproval";
import { RepeatedEngagement } from "./RepeatedEngagement";

export type ReferralComplianceProps = Pick<
  ReferralDetail,
  | "id"
  | "projectId"
  | "application"
  | "compliance"
  | "complianceNote"
  | "chaperoneCall"
>;

interface ComplianceProps {
  expertId: string;
  referral: ReferralComplianceProps;
  complianceInfo: ComplianceType[];
  repeatedEngagementDetails?: RepeatedEngagementDetails;
  doNotContactStatus: DO_NOT_CONTACT_STATUS;
  onUpdateComplianceAnswers: (answers: ComplianceType[]) => void;
  onUpdateComplianceStatus: (
    chaperoneCall: boolean,
    complianceNote: string,
    status: PROJECT_REFERRAL_STATE,
    compliance: ComplianceStatus
  ) => void;
}

export const Compliance: React.FC<ComplianceProps> = ({
  expertId,
  referral,
  complianceInfo,
  repeatedEngagementDetails,
  doNotContactStatus,
  onUpdateComplianceAnswers,
  onUpdateComplianceStatus
}) => {
  const { t } = useTranslation("referralCompliance");
  const gatekeepingFF = useFeature(FEATURE_FLAGS.Gatekeeping);

  const loggedInUser = useSelector(CacheSelector.loggedInUser());

  const isComplianceManager = !!loggedInUser.complianceManager;
  const isCompliancePending =
    referral.compliance.subStatus === REFERRAL_COMPLIANCE_STATE.PENDING;

  return (
    <Flex vertical gap={16}>
      {complianceInfo.length > 0 && (
        <AntDCollapse
          defaultActiveKey="complianceAnswers"
          items={[
            {
              key: "complianceAnswers",
              label: t("complianceQuestions"),
              children: (
                <ComplianceAnswers
                  expertId={expertId}
                  referral={referral}
                  complianceInfo={complianceInfo}
                  doNotContactStatus={doNotContactStatus}
                  onUpdateComplianceAnswers={onUpdateComplianceAnswers}
                />
              )
            }
          ]}
        />
      )}
      {isComplianceManager && isCompliancePending && (
        <AntDCollapse
          defaultActiveKey="complianceQuestionApproval"
          items={[
            {
              key: "complianceQuestionApproval",
              label: t("complianceQuestionApproval"),
              children: (
                <ComplianceQuestionsApproval
                  referralId={referral.id}
                  projectId={referral.projectId}
                  onUpdateComplianceStatus={onUpdateComplianceStatus}
                />
              )
            }
          ]}
        />
      )}
      {gatekeepingFF &&
        repeatedEngagementDetails &&
        (repeatedEngagementDetails.pastEngagements.length > 0 ||
          repeatedEngagementDetails.pendingEngagements.length > 0) && (
          <AntDCollapse
            defaultActiveKey="repeatedEngagements"
            items={[
              {
                key: "repeatedEngagements",
                label: t("repeatedEngagement"),
                children: (
                  <RepeatedEngagement
                    projectId={referral.projectId}
                    repeatedEngagementDetails={repeatedEngagementDetails}
                  />
                )
              }
            ]}
          />
        )}
    </Flex>
  );
};
