import { AntDIcon } from "@arbolus-technologies/antDComponents";
import {
  CustomersService,
  DefaultToasterService
} from "@arbolus-technologies/api";
import {
  CUSTOMER_INSIGHT_ANSWER_STATUS,
  InsightAnswerUpdateStatus
} from "@arbolus-technologies/models/common";
import { ARBOLUS_COLORS } from "@arbolus-technologies/theme";
import { ICellRendererParams } from "ag-grid-community";
import { Button, Flex, Tag } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { INSIGHT_ANSWER_STATUS_TO_COLOR } from "../../constants";
import { ANSWER_STATUS_TRANSLATION } from "../../pages/CustomerSurveysPage/CompanyDataTable/companyDataTableDefinition";
import styles from "./ActionRenderer.module.scss";

const { Approved, Rejected, InReview } = CUSTOMER_INSIGHT_ANSWER_STATUS;

interface ICellRendererParamsWithAnswerStatus extends ICellRendererParams {
  data: {
    id: string;
    answerStatus: CUSTOMER_INSIGHT_ANSWER_STATUS;
  };
}

export const ActionRenderer: React.FC<ICellRendererParamsWithAnswerStatus> = ({
  data,
  api
}) => {
  const { t } = useTranslation("customerSurveyAnswerTable");
  const answerStatus: CUSTOMER_INSIGHT_ANSWER_STATUS =
    data.answerStatus as CUSTOMER_INSIGHT_ANSWER_STATUS;

  function updateAnswerStatus(status: InsightAnswerUpdateStatus) {
    CustomersService.updateInsightAnswerStatus(data.id, { status }).then(
      () => {
        DefaultToasterService.showSuccess(t("answerStatusUpdated"));
        const rowNode = api.getRowNode(data.id);
        rowNode?.setData({
          ...rowNode.data,
          id: data.id,
          answerStatus: status
        });
      },
      () => DefaultToasterService.showError(t("answerStatusError"))
    );
  }

  if (answerStatus === InReview) {
    return (
      <Flex gap={16} align="center" className={styles.pendingWrapper}>
        <Button
          danger
          size="small"
          type="primary"
          icon={<AntDIcon name="close" />}
          onClick={() => updateAnswerStatus(Rejected)}
        />
        <Button
          size="small"
          type="primary"
          style={{ backgroundColor: ARBOLUS_COLORS.bColorSuccess }}
          icon={<AntDIcon name="check" />}
          onClick={() => updateAnswerStatus(Approved)}
        />
      </Flex>
    );
  }

  return (
    <Tag color={INSIGHT_ANSWER_STATUS_TO_COLOR[answerStatus]}>
      {ANSWER_STATUS_TRANSLATION[answerStatus] ?? answerStatus}
    </Tag>
  );
};
