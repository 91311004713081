import {
  MONITORING_LIST_ORDER_BY,
  MonitoringListService,
  SORT_DIRECTION,
  UserMonitoringListModel,
  UserMonitoringListRequest
} from "@arbolus-technologies/api";
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest
} from "ag-grid-community";

export class UserMonitoringListDataSource implements IServerSideDatasource {
  private readonly _userId: string;
  private readonly _setNumOfCompanies: (numCompanies: number) => void;

  constructor(
    userId: string,
    setNumOfCompanies: (numCompanies: number) => void
  ) {
    this._userId = userId;
    this._setNumOfCompanies = setNumOfCompanies;
  }

  getRows(
    params: IServerSideGetRowsParams<UserMonitoringListModel, never>
  ): void {
    const { success, fail, request } = params;

    const queryParams = this.getQueryParams(request);

    MonitoringListService.getUserCompanySignal({
      ...queryParams,
      userId: this._userId
    })
      .then((response) => {
        this._setNumOfCompanies(response.pagination.count);
        success({
          rowData: response.items,
          rowCount: response.pagination.count
        });
      })
      .catch(fail);
  }

  private getQueryParams(
    request: IServerSideGetRowsRequest
  ): UserMonitoringListRequest {
    const { startRow, endRow } = request;

    const queryParams: UserMonitoringListRequest = {
      limit: endRow! - startRow!,
      offset: startRow!,
      sort: [
        {
          orderBy: MONITORING_LIST_ORDER_BY.STATUS,
          orderDirection: SORT_DIRECTION.ASCENDING
        },
        {
          orderBy: MONITORING_LIST_ORDER_BY.CREATED,
          orderDirection: SORT_DIRECTION.DESCENDING
        }
      ]
    };

    return queryParams;
  }
}
