import { AVAILABLE_TAGS } from "./enums";

export const EMAIL_NOTIFICATION_AVAILABLE_TAGS = [
  AVAILABLE_TAGS.CLIENT_NAME,
  AVAILABLE_TAGS.EXPERT_NAME,
  AVAILABLE_TAGS.TIMEFRAME,
  AVAILABLE_TAGS.FREQUENCY,
  AVAILABLE_TAGS.EXPERT_CURRENT_WORK_HISTORY,
  AVAILABLE_TAGS.CLIENT_TEAM_MEMBERS,
  AVAILABLE_TAGS.PROJECT_NAME,
  AVAILABLE_TAGS.EXPERT_CONSULTATIONS_NUMBER,
  AVAILABLE_TAGS.PROJECT_CASE_CODES,
  AVAILABLE_TAGS.TOTAL_ENGAGEMENTS_BY_PROJECT
];

export const APPROVAL_REQUIRED_AVAILABLE_TAGS = [
  ...EMAIL_NOTIFICATION_AVAILABLE_TAGS,
  AVAILABLE_TAGS.REVIEW_EXPERT_CTA
];
