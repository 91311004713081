export enum CUSTOMER_INSIGHT_ANSWER_STATUS {
  Pending = "Pending",
  InReview = "InReview",
  Approved = "Approved",
  Rejected = "Rejected"
}

export type InsightAnswerUpdateStatus =
  | CUSTOMER_INSIGHT_ANSWER_STATUS.Approved
  | CUSTOMER_INSIGHT_ANSWER_STATUS.Rejected;

export interface Vendor {
  vendorCompanyId: string; // company ID
  vendorCompanyName: string; // expert is a customer of
  customerInsightId: string;
  answerStatus?: CUSTOMER_INSIGHT_ANSWER_STATUS;
}

export interface JobWithVendors {
  expertWorkHistoryId: string; // workHistoryId
  expertCompanyId: string;
  expertCompanyName: string; // where the expert worked
  jobTitle: string;
  isCurrentJob: boolean;
  insightVendors: Vendor[];
}
