export enum ENGAGEMENT_TYPE_ENUM {
  Calls = "Calls",
  Canopies = "Canopies"
}

export enum AVAILABLE_TAGS {
  CLIENT_NAME = "clientName",
  EXPERT_NAME = "expertName",
  TIMEFRAME = "timeframe",
  FREQUENCY = "frequency",
  EXPERT_CURRENT_WORK_HISTORY = "expertCurrentWorkHistory",
  CLIENT_TEAM_MEMBERS = "clientTeamMembers",
  PROJECT_NAME = "projectName",
  EXPERT_CONSULTATIONS_NUMBER = "expertConsultationsNumber",
  PROJECT_CASE_CODES = "projectCaseCodes",
  TOTAL_ENGAGEMENTS_BY_PROJECT = "totalEngagementsByProject",
  REVIEW_EXPERT_CTA = "reviewExpertCTA"
}

export enum GATEKEEPING_SETTING_FORM {
  PRODUCTS = "products",
  COMMUNICATION_TYPE = "communicationType",
  ENGAGEMENT_COUNT = "engagementCount",
  MONTH_TIMEFRAME = "monthTimeframe",
  SUBJECT = "subject",
  CONTENT = "content",
  ADDITIONAL_CONTACTS = "additionalContacts"
}
