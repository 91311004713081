import { ICellRendererParams } from "ag-grid-community";
import { Tag, Tooltip, Typography } from "antd";
import styles from "./CompetitorsNamesRenderer.module.scss";

export interface CompetitorsNamesRendererProps
  extends Partial<ICellRendererParams<{ competitorsNames: string[] }>> {}

export function CompetitorsNamesRenderer({
  data
}: CompetitorsNamesRendererProps) {
  if (!data?.competitorsNames || data.competitorsNames.length === 0)
    return <Typography.Text type="secondary">-</Typography.Text>;

  const { competitorsNames } = data;
  const companies = competitorsNames.slice(0, 3);

  return (
    <div className={styles.container}>
      {companies.map((company, index) => (
        <Tooltip title={companies.join(", ")} key={index}>
          <Tag className={styles.tag}>{company}</Tag>
        </Tooltip>
      ))}
    </div>
  );
}
